import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import AddNewFakeAccountComp from "./AddNewFakeAccountComp";


const API_URL = `https://dashapi.ingochat.com/`


const FakeAccountsGqlSchema = 
`id
AccountId{
    id
    StandardAccount{
        Credentials{
            Email
        }
    }
    AccountData{
        MainInfo{
            FullName
        }
    }
}
CreatedAt
`

const FakeAccounts = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [fakeAccounts,setFakeAccounts] = useState([])
    const [fakeAccountsCount,setFakeAccountsCount] = useState(0)

    const HandleMainInfoSubmit = (email,fullname,gender,maritalstatus,birthdate,origincountryid,origincityit,isStudying,StudyName,isWorking,WorkName,hope,imgfilepath) =>{
        AddNewFakeAccount(email,fullname,gender,maritalstatus,birthdate,origincountryid,origincityit,isStudying,StudyName,isWorking,WorkName,hope,imgfilepath)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewFakeAccountComp HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setModalOpen(true)
        setModalStatus('ADD')
    }

    
  
    const getFakeAccountCounts = async () =>{
        try{
          const res = await axios.post(API_URL,{
            query:`query{
              GetStaticNumber(key:"fakeAccountsCount"){
                Value
              }
            }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setFakeAccountsCount(res.data.data.GetStaticNumber.Value)          
          }
        }
        catch(err){}
      }

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteFakeAccount(id:"${interest.AccountId.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetFakeAccounts(limit:${rowsPerPage},offset:${page}){
                        ${FakeAccountsGqlSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setFakeAccounts(res.data.data.GetFakeAccounts)
          setLoaderVisibility('none')
          getFakeAccountCounts()
          
        }
      }
      catch(err){
  
      }
    }

    const AddNewFakeAccount = async(email,fullname,gender,maritalstatus,birthdate,origincountryid,origincityit,isStudying,StudyName,isWorking,WorkName,hope,imgfilepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                GenerateFakeAccount(email:"${email}",fullname:"${fullname}",gender:${gender},maritalstatus:${maritalstatus},birthdate:"${birthdate}",origincountryid:"${origincountryid}",origincityit:"${origincityit}",isStudying:${isStudying},StudyName:"${StudyName}",isWorking:${isWorking},WorkName:"${WorkName}",hope:"${hope}",imgfilepath:"${imgfilepath}") {
                  ${FakeAccountsGqlSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.GenerateFakeAccount.id !== null){
            fetchData()
          }
    }

   
 
    useEffect(() => {
        fetchData()
    }, [rowsPerPage,page]);
    
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Fake Accounts`} subtitle={`Generate and delete fake accounts`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                 
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Generate New Fake Account</Button>

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    fakeAccounts.map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.AccountId.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{itm.AccountId.StandardAccount.Credentials.Email}</TableCell>
        <TableCell>
            <IconButton color="error" onClick={() => handleDeleteClick(itm)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={fakeAccountsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default FakeAccounts