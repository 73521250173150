import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch,List,ListItem,ListItemAvatar,Avatar,ListItemText } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const DistroGQL = `
id
Account{
  id
  AccountData{
    MainInfo{
      FullName
    }
    MainImages{
      FilePath
    }
  }
}
CreatedAt
`
const ColorPalletRelatedAccount = ({Item,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);    

  const [colorPallet,setColorPallet] = useState(Item)
    const [data,setData] = useState([])
    
    const fetchData = async() =>{
      try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetAccountHoldingColorPallet(id:"${Item.id}"){
                        ${DistroGQL}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setData(res.data.data.GetAccountHoldingColorPallet)
        }
      }
      catch(err){
  
      }
    }

    useEffect(()=>{
      fetchData()
    },[])
 
  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Accounts Have This Color Pallet</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center"
      }}>{colorPallet.Title.En} - {colorPallet.Title.Ar}</Typography>

      
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
            <List sx={{ width: '100%', display:"flex", flexWrap:"wrap", gap:1 }}>
                {
                    data.map((dt,ind)=>(
                        <ListItem sx={{
                            maxWidth: '19%',border:1,borderRadius:2
                        }}>
                        <ListItemAvatar>
                        <Avatar>
                            <img src={`https://cloud.ingochat.com/${dt.Account.MainImages[dt.Account.MainImages.length - 1 ].FilePath}`} />
    
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={dt.Account.AccountData.MainInfo.FullName} secondary={dt.CreatedAt} />
                    </ListItem>
                    )
                       
                    )
                }
               
            </List>
        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default ColorPalletRelatedAccount;