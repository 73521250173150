import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel, Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from "axios"

import { tokens } from "../../../theme"

import { getAuthentication } from "../../../auth"
import AddNewAd from "./AddNewAd"
import { RemoveRedEyeOutlined } from "@mui/icons-material"
import DisplayAd from "./DisplayAd"


const API_URL = `https://dashapi.ingochat.com/`


const AdGQLSchema = 
`
id
MainInfo{
    Title{
        En
        Ar
    }
    Description{
        En
        Ar
    }
    Type
}
MainImage{
    FilePath
}
Buttons{
    Button1{
        Text{
            En
            Ar
        }
        Link
    }
    Button2{
        Text{
            En
            Ar
        }
        Link
    }
}
Duration{
    StartDate
    EndDate
}
Enabled
CreatedAt
`

const Ads = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewAd,setIsNewAd] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [ads,setAds] = useState([])
    const [selectedAd,setSelectedAd] = useState(null)
    const [adsCount,setAdsCount] = useState(0)

    const HandleMainInfoSubmit = (enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath) =>{
        isNewAd ? AddNewAdFunc(enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath) : EditAdFunc(enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewAd NewAd={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewAd NewAd={false} Ad={selectedAd} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "DSPSTD":<DisplayAd Ad={selectedAd} closingModal={handleCloseModal} />
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewAd(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleNewPollBtnClick = () =>{
      setIsNewAd(true)
      setModalOpen(true)
      setModalStatus('ADDPOLL')
    }

    const handleEditClick = (interest) =>{
        setSelectedAd(interest)
        setModalStatus("EDIT")
        setIsNewAd(false)
        setModalOpen(true)
    }
    
    const HandleDisplayAd = (ad) =>{
      setSelectedAd(ad)
      setModalStatus("DSPSTD")
      setModalOpen(true)
    }

    const handleChangeEnable = async(state,item) => {
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                ToggleEnableAd(id:"${item.id}",enable:${state}) {
                  ${AdGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
          if(res.data.data.ToggleEnableAd.id !== null){
            fetchData()
          }
    }

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteAd(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
          fetchData()
        } catch (error) {}
    }

    const GetAdsCounts = async () =>{
      try{
        const res = await axios.post(API_URL,{
          query:`query{
            GetStaticNumber(key:"AdCount"){
              Value
            }
          }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setAdsCount(res.data.data.GetStaticNumber.Value)          
        }
      }
      catch(err){}
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetAds(limit:${rowsPerPage},offset:${page}){
                        ${AdGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setAds(res.data.data.GetAds)
          setLoaderVisibility('none')
          GetAdsCounts()
        }
      }
      catch(err){}
    }

    const AddNewAdFunc = async(enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath) => {
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewAd(entitle:"${enTitle}",artitle:"${arTitle}",endescription:"${enDescription}",ardescription:"${arDescription}",type:"${type}",startdate:"${startDate}",enddate:"${endDate}",enbtn1txt:"${btn1TextEn}",arbtn1txt:"${btn1TextAr}",btn1lnk:"${btn1Link}",enbtn2txt:"${btn2TextEn}",arbtn2txt:"${btn2TextAr}",btn2lnk:"${btn2Link}",filepath:"${filepath}") {
                  ${AdGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewAd.id !== null){
            fetchData()
          }
    }


   

    

    const EditAdFunc = async(enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath) => {
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditAd(id:"${selectedAd.id}",entitle:"${enTitle}",artitle:"${arTitle}",endescription:"${enDescription}",ardescription:"${arDescription}",type:"${type}",startdate:"${startDate}",enddate:"${endDate}",enbtn1txt:"${btn1TextEn}",arbtn1txt:"${btn1TextAr}",btn1lnk:"${btn1Link}",enbtn2txt:"${btn2TextEn}",arbtn2txt:"${btn2TextAr}",btn2lnk:"${btn2Link}",filepath:"${filepath}"){
                        ${AdGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditAd.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
      fetchData()
    }, [rowsPerPage,page])

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Ads`} subtitle={`Add, modify, manage and delete ads`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                   <Box>
                   <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Ad</Button>

                   </Box>
                  

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Title</TableCell>
        <TableCell>Ad Date</TableCell>
        <TableCell>Enable</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    ads.map(pst => (
        <TableRow key={pst.id}>
        <TableCell>{pst.MainInfo.Title.En} - {pst.MainInfo.Title.Ar}</TableCell>
        <TableCell>{pst.CreatedAt}</TableCell>
        <TableCell>
            <Switch checked={pst.Enabled} onChange={(e)=>{handleChangeEnable(e.target.checked,pst)}} color="secondary" />
        </TableCell>
        <TableCell>
        { /*  <IconButton color="warning" onClick={()=>HandleDisplayAd(pst)}>
            <RemoveRedEyeOutlined />
          </IconButton>
        */
        }
          <IconButton color="secondary" onClick={() => handleEditClick(pst)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(pst)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={adsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default Ads