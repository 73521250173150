import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel, Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from "axios"

import { tokens } from "../../../theme"

import { getAuthentication } from "../../../auth"



const API_URL = `https://dashapi.ingochat.com/`


const ProfanityGQLSchema = 
`
id
Text
CreatedAt
`

const Profanity = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewAd,setIsNewAd] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [profanity,setProfanity] = useState([])
    const [wordToAdd,setWordToAdd] = useState('')

 

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

 
    
 

    

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteProfanity(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
          fetchData()
        } catch (error) {}
    }

  

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetProfanityList{
                        ${ProfanityGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setProfanity(res.data.data.GetProfanityList)
          setLoaderVisibility('none')
        }
      }
      catch(err){}
    }

    const AddNewProfanity = async() => {
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewProfanity(text:"${wordToAdd}") {
                  ${ProfanityGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewProfanity.id !== null){
            setWordToAdd('')
            fetchData()
          }
    }


    useEffect(() => {
      fetchData()
    }, [rowsPerPage,page])

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Profanity List`} subtitle={`Add and delete profanities`} />
         

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                   <Box sx={{
                    display:"flex",
                    gap:2
                   }}>
                    <TextField 
                    variant="filled"
                    type="text"
                    sx={{
                        width:"280px"
                    }}
                    value={wordToAdd}
                    onChange={(e)=>setWordToAdd(e.target.value)}
                    color="secondary"
                    label="Word to add ...  " />
                   <Button variant="contained" onClick={AddNewProfanity} color="secondary">Add New Profanity</Button>

                   </Box>
                  

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Text</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    profanity.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(pst => (
        <TableRow key={pst.id}>
        <TableCell>{pst.Text}</TableCell>
        <TableCell>{pst.CreatedAt}</TableCell>
        <TableCell>
            <IconButton onClick={()=>handleDeleteClick(pst)} color="error">
                <DeleteIcon />
            </IconButton>
        </TableCell>
     
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={profanity.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default Profanity