import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem,Tab,Tabs, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckCircleOutline, CheckOutlined } from "@mui/icons-material";
import AccountDataInfo from "./AccountDataInfo";
import PropTypes from 'prop-types';
import AccountLogs from "./AccountLogs";
import AccountImages from "./AccountImages";
import AccountFavouriteItems from "./FavouriteItems";
import AccountStore from "./AccountStore";
import AccountWarnings from "./AccountWarnings";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const InterestSchemaGQL = `
id
InterestName{
  ar
  en
}
`

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const DisplayStandardAccount = ({handleToggleBanState,Account,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [tabValue,setTabValue] = useState(0)

  const handleChangeTab = (event, newValue) =>{
    setTabValue(newValue)
  }

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "85%",
  height:"85%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{textAlign:"center"}}>Account Details</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
         <Box sx={{display:"flex",mt:4}}>
        
         <Tabs
         indicatorColor="secondary"  textColor="secondary"
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider',width:"12%" }}
      >
        <Tab sx={{fontSize:'14px'}} label="Account Data Info"  />
        <Tab sx={{fontSize:'14px'}} label="Account Images"  />
        <Tab sx={{fontSize:'14px'}} label="Account Favourite Items"  />
        <Tab sx={{fontSize:'14px'}} label="Account Store"  />
        <Tab sx={{fontSize:'14px'}} label="Account Logs" />
        <Tab sx={{fontSize:'14px'}} label="Account Warnings" />
       
      </Tabs>
      <Box sx={{width:"85%"}}>
      <TabPanel  value={tabValue} index={0}>
        <AccountDataInfo handleToggleBanState={handleToggleBanState} Account={Account}/>
      </TabPanel>
     
      <TabPanel  value={tabValue} index={1}>
          <AccountImages Account={Account}/>
      </TabPanel>
      <TabPanel  value={tabValue} index={2}>
          <AccountFavouriteItems Account={Account} />
      </TabPanel>
      <TabPanel  value={tabValue} index={3}>
          <AccountStore Account={Account} />
      </TabPanel>
      <TabPanel  value={tabValue} index={4}>
        <AccountLogs Account={Account} />
      </TabPanel>
      <TabPanel  value={tabValue} index={5}>
        <AccountWarnings Account={Account} />
      </TabPanel>
      </Box>
      
      </Box>
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default DisplayStandardAccount;