import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { Category } from "@mui/icons-material";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const RatingTypeSchemaGQL = `
id
MainInfo{
  Title{
    Ar
    En
  }
  Description{
    Ar
    En
  }
}
RateConfig{
    isThereRateKeys
    RateKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
        Key3{
            Enabled
            Ar
            En
        }
        Key4{
            Enabled
            Ar
            En
        }
    }
    RateFrom
    OtherKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
    }
}
Infos{
    categoriesCount
    Count
    CreatedAt
}
`


const AddNewRatingCategory = ({HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ar,setAr] = useState('')
  const [en,setEn] = useState('')

  const [arDescription,setArDescription] =useState('')
  const [enDescription,setEnDescription] = useState('')

  const [msg,setMSG] = useState('')
  const [ratingTypes,setRatingTypes] = useState([])


  
  const [rateType,setRateType] = useState(null)
  
  const HandleSubmitBtn = () =>{ 
    HandleMainInfoSubmit(ar,en,rateType,arDescription,enDescription)
    closingModal()
}


  



 const fetchData = async () => {


  try{

 const res = await axios.post(API_URL,{
      query:`query{
        GetAllRatingTypes{
          ${RatingTypeSchemaGQL}
        }
      }`
  },{
    headers:{
      token:`Bearer ${getAuthentication()}`
    }
  })
  if(res.status === 200){
    setRatingTypes(res.data.data.GetAllRatingTypes)    
  }
}
catch(err){

}
}

useEffect(() => {
  fetchData()
  }, [])


  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Add New Category</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
          <Box sx={{p:1,px:2}}>
        <InputLabel color="secondary" sx={{mb:1}} id="demo-simple-select-label">Rating Type  </InputLabel>
        <Select variant="filled"  value={rateType} onChange={(e)=>setRateType(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Type ..." >
            {
              ratingTypes.map(tp=>(
                <MenuItem key="cvrtp1" value={tp.id} >{tp.MainInfo.Title.Ar} - {tp.MainInfo.Title.En}</MenuItem>         

              ))
            }
        </Select>
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Type Name (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Type Name (ar) ..."
                    color="secondary"
                    />
        </Box>
                    
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>


<TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={enDescription}
                    onChange={(e)=>setEnDescription(e.target.value)}
                    label="Type Description (en) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={arDescription}
                    onChange={(e)=>setArDescription(e.target.value)}
                    label="Type Description (ar) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />

        </Box>
         
      
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Category</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewRatingCategory