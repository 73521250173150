import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import { useState,useEffect } from "react"
import { tokens } from "../../../theme";
import axios from "axios"
import { getAuthentication } from "../../../auth"


import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AddNewAccountCover from "./AddNewAccountCover";

const API_URL = `https://dashapi.ingochat.com/`

const AccountCoverSchemaGQL = `
id
CoverType
MainInfo {
    Name {
        ar
        en
    }
    Category {
    id
        Name {
            ar
            en
        }
    }
    CoverImage {
        FilePath
    }
    CoverColor {
        Hex
    }
}
Price
Infos {
    createdAt
}
`

const AccountCover = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [accountCovers,setAccountCovers] = useState([])
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [selectedCover,setSelectedCover] = useState(null)
    const [isNewCover,setIsNewCover] = useState(true)

    const HandleMainInfoSubmit = (ar,en,coverType,coverCategory,filepath,price,hex) =>{
        isNewCover ? AddNewCoverFunc(ar,en,coverType,coverCategory,filepath,price,hex) : EditCoverFunc(ar,en,coverType,coverCategory,filepath,price,hex)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents = {
        "ADD": <AddNewAccountCover NewCover={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewAccountCover NewCover={false} Cover={selectedCover} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const AddNewCoverFunc = async(ar,en,covertype,categoryId,filename,price,hexColor) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewAccountCover(covertype:"${covertype}",ar:"${ar}",en:"${en}",categoryId:"${categoryId}",filename:"${filename}",hexColor:"${hexColor}",price:${price}) {
                  ${AccountCoverSchemaGQL}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewAccountCover.id !== null){
            fetchData()
          }
    }

    const EditCoverFunc = async (ar,en,covertype,categoryId,filename,price,hexColor) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditAccountCover(id:"${selectedCover.id}",covertype:"${covertype}",ar:"${ar}",en:"${en}",categoryId:"${categoryId}",filename:"${filename}",hexColor:"${hexColor}",price:${price}){
                        ${AccountCoverSchemaGQL}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditAccountCover.id !== null){
            fetchData()
        }
    }

    const handleNewBtnClick = () =>{
        setIsNewCover(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleDeleteClick = async(category) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteAccountCover(id:"${category.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const handleEditClick = (category) =>{
        setSelectedCover(category)
        setModalStatus("EDIT")
        setIsNewCover(false)
        setModalOpen(true)
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')  
        try{
        const res = await axios.post(API_URL,{
            query:`query{
                getAccountCovers{
                ${AccountCoverSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setAccountCovers(res.data.data.getAccountCovers)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){}
    }

    useEffect(() => {
        fetchData()
        }, [])


    return(
       <Box sx={{
            mt:2
       }}> 
       <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{display:isLoaderVisible === 'flex' ? 'flex' : 'none',justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Cover</Button>
            </Box>


            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Cover Name (ar)</TableCell>
        <TableCell>Cover Name (en)</TableCell>
        <TableCell>Cover Type</TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {   
    accountCovers.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(cvr => (
        <TableRow key={cvr.id}>
        <TableCell>{cvr.MainInfo.Name.ar}</TableCell>
        <TableCell>{cvr.MainInfo.Name.en}</TableCell>
        <TableCell>{cvr.CoverType}</TableCell>
        <TableCell>{cvr.Price}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(cvr)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(cvr)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))
    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={accountCovers.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
       </Box>
    )
}
export default AccountCover