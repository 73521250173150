import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const AddNewChatRoomTopNav = ({NewItem,Item,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const [ar,setAr] = useState(NewItem?'':Item.Title.Ar)
  const [en,setEn] = useState(NewItem?'':Item.Title.En)
  const [price,setPrice] = useState(NewItem? 0 : Item.Price)
  const [isDiscount,setIsDiscount] = useState(NewItem? false : Item.Discount.isDiscount)
  const [discountType,setDiscountType] = useState(NewItem? "PERCENTAGE" : Item.Discount.DiscountType)
  const [amount,setAmount] = useState(NewItem ? "" : Item.Discount.Amount)
  const [priority,setPriority] = useState(NewItem? 1 : Item.Priority)
  const [filepath,setFilePath] = useState(NewItem?'':Item.Image.FilePath)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')


  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(filepath,price,en,ar,isDiscount,discountType,amount === '' ? 0 : amount,priority)
    closingModal()

  }

  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setFilePath(res.data.name)
    setMSG('File has uploaded successfully')
   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewItem ? "Add New Chat Room Top Nav" : "Edit Chat Room Top Nav"}</Typography>
      {!NewItem ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Item.Title.Ar + ' - ' + Item.Title.En}</Typography>):(<></>) }
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Item Title (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Item Title (ar) ..."
                    color="secondary"
                    />
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={price}
                    onChange={(e)=>setPrice(e.target.value)}
                    label="Price"
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={priority}
                    onChange={(e)=>setPriority(e.target.value)}
                    label="Priority"
                    color="secondary"
                    />
        </Box>
        <Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>
        </Box>
        <Typography sx={{textAlign:'center',mt:1}} color="error">{msg}</Typography>
        <Box sx={{
            display:"flex",
            alignItems:"center"
        }}> 
            <Switch checked={isDiscount} color="secondary" onChange={()=>{setIsDiscount(!isDiscount)}}/>
            <Typography>is there a discount</Typography>
        </Box>
        {
            isDiscount && 
            <Box sx={{
                display:'flex',
                justifyContent:'space-around'
            }}>
                <Select sx={{
                        mt:3,
                        width:'48%'
                    }}
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value="PERCENTAGE">Percentage (The discount applied will be calculated as a percentage reduction from the original price.)</MenuItem>
                <MenuItem value="AMOUNT">Amount (The pricing mechanism involves a fixed price, devoid of any percentage adjustments, resulting in a definite new price.)</MenuItem>
              </Select>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={amount}
                    onChange={(e)=>setAmount(e.target.value)}
                    label="Discount Amount"
                    color="secondary"
                    />
            </Box>
        }
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewItem? `Add Chat Room Top Nav` : `Edit Chat Room Top Nav`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewChatRoomTopNav;