import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckCircleOutline, CheckOutlined } from "@mui/icons-material";
const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const InterestSchemaGQL = `
id
InterestName{
  ar
  en
}
`

const DisplayGuestAccount = ({handleToggleBanState,Account,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [banState,setBanState] = useState(Account.AccountStatus.Banned)

  const handleSaveBtn = () => {
    handleToggleBanState(banState)
    closingModal()
  }

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"40%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{textAlign:"center"}}>Guest Account Details</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
         
              <Box sx={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between",
                mt:4,
                px:2
              }}>
                <Box sx={{display:"flex",alignItems:"center",gap:2}}>
                    <Typography variant="h4">Notification</Typography>
                    {Account.AppConfig.Notification ? <CheckOutlined color="secondary" /> : <CloseIcon  color="error"/>}
                </Box>
                <Box sx={{display:"flex",alignItems:"center",gap:2}}>
                    <Typography variant="h4">Dark Mode</Typography>
                    {Account.AppConfig.DarkMode ? <CheckOutlined color="secondary" /> : <CloseIcon  color="error"/>}
                </Box>
                <Box sx={{display:"flex",alignItems:"center",gap:2}}>
                    <Typography variant="h4">App Language</Typography>
                    <Typography sx={{alignItems:"center",mt:.5}}>{Account.AppConfig.AppLanguage}</Typography>
                </Box>
              </Box>
              <Box sx={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between",
                mt:2,
                px:2
              }}>
                <Box sx={{display:"flex",alignItems:"center",gap:2}}>
                    <Typography variant="h4">Activated Status</Typography>
                    {Account.AccountStatus.Activated ? <CheckOutlined color="secondary" /> : <CloseIcon  color="error"/>}
                </Box>
                <Box sx={{display:"flex",alignItems:"center",gap:2}}>
                    <Typography variant="h4">Banned</Typography>
                    <Switch color="secondary" checked={banState} onChange={()=>setBanState(!banState)} />
                </Box>
            </Box>

            <Box sx={{mt:1,px:2}}>
                <Button sx={{mt:2}} onClick={()=>handleSaveBtn()} variant="contained" color="secondary">Save</Button>

            </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default DisplayGuestAccount;