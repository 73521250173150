import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,Switch,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { Category } from "@mui/icons-material";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const AddNewRatingType = ({HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  


  const [ar,setAr] = useState('')
  const [en,setEn] = useState('')

  const [arDescription,setArDescription] =useState('')
  const [enDescription,setEnDescription] = useState('')

  const [isThereRateKeys,setIsThereRateKey] = useState(false)
  const [rateFrom,setRateFrom] = useState(5)
  const [rateKeys, setRateKeys] = useState({
    Key1: { Enabled: false, Ar: '', En: '' },
    Key2: { Enabled: false, Ar: '', En: '' },
    Key3: { Enabled: false, Ar: '', En: '' },
    Key4: { Enabled: false, Ar: '', En: '' }
  })
  const [isOtherRate,setIsOtherKey] = useState(false)
  const [otherRates,setOtherRates] = useState( {
    Key1:{
        Enabled:false,
        Ar:'',
        En:''
    },
    Key2:{
        Enabled:false,
        Ar:'',
        En:''
    }
  })



  
  
const HandleSubmitBtn = () =>{ 

    HandleMainInfoSubmit(ar,en,arDescription,enDescription,isThereRateKeys,rateFrom,rateKeys,otherRates)
    closingModal()
}

const handleOtherRateChange = (key, field, value) =>{
    setOtherRates(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [field]: value
        }
    }));
}

const handleRateChange = (key, field, value) => {
    setRateKeys(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [field]: value
      }
    }));
  }

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Add New Type</Typography>

      <Typography color="error" sx={{
        textAlign:"center",
        mt:1
      }}>Please ensure that all input fields are accurate, as the 'Rating' types are not editable. </Typography>
     
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
          
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Type Name (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Type Name (ar) ..."
                    color="secondary"
                    />
        </Box>
                    
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>


<TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={enDescription}
                    onChange={(e)=>setEnDescription(e.target.value)}
                    label="Type Description (en) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={arDescription}
                    onChange={(e)=>setArDescription(e.target.value)}
                    label="Type Description (ar) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />

        </Box>
        <Box sx={{p:1,px:2}}>
        <InputLabel color="secondary" sx={{mb:1}} id="demo-simple-select-label">Rate From  </InputLabel>
        <Select variant="filled"  value={rateFrom} onChange={(e)=>setRateFrom(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Type ..." >
          <MenuItem key="cvrtp1" value="5">5</MenuItem>         
          <MenuItem key="cvrtp2" value="10">10</MenuItem>         
        </Select>
        </Box>
         
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',p:1,px:2
        }}>
        <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={isThereRateKeys}
            onChange={e => setIsThereRateKey(e.target.checked)}
          />
        }
        label="Is there rate keys?"
      />
      
      </Box>
      {isThereRateKeys && (
        <>
        
          {Object.keys(rateKeys).map(key => (
            <Box sx={{
                display:'flex',
                justifyContent:'space-around',p:1,px:2
            }} key={key}>
              <FormControlLabel
                sx={{
                    width:'19%'
                }}
                control={
                  <Switch
                    color="secondary"
                    checked={rateKeys[key].Enabled}
                    onChange={e =>
                      handleRateChange(key, 'Enabled', e.target.checked)
                    }
                  />
                }
                label={`Enable ${key}`}
              />
              <TextField
               sx={{
                width:'39%'
            }}
                label={"Arabic " + key}
                value={rateKeys[key].Ar}
                color="secondary"

                onChange={e =>
                  handleRateChange(key, 'Ar', e.target.value)
                }
                fullWidth
              />
              <TextField
              sx={{
                width:'39%'
            }}
            label={"English " + key}
            value={rateKeys[key].En}
            color="secondary"

                onChange={e =>
                  handleRateChange(key, 'En', e.target.value)
                }
                fullWidth
              />
            </Box>
          ))}
        </>
      )}

        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',p:1,px:2
        }}>
        <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={isOtherRate}
            onChange={e => setIsOtherKey(e.target.checked)}
          />
        }
        label="Is there other rate keys?"
      />
      
      </Box>

      {isOtherRate && (
        <>
        
          {Object.keys(otherRates).map(key => (
            <Box sx={{
                display:'flex',
                justifyContent:'space-around',p:1,px:2
            }} key={key}>
              <FormControlLabel
                sx={{
                    width:'19%'
                }}
                control={
                  <Switch
                    color="secondary"
                    checked={otherRates[key].Enabled}
                    onChange={e =>
                        handleOtherRateChange(key, 'Enabled', e.target.checked)
                    }
                  />
                }
                label={`Enable ${key}`}
              />
              <TextField
               sx={{
                width:'39%'
            }}
                label={"Arabic " + key}
                value={otherRates[key].Ar}
                color="secondary"

                onChange={e =>
                  handleOtherRateChange(key, 'Ar', e.target.value)
                }
                fullWidth
              />
              <TextField
                    sx={{
                        width:'39%'
                    }}
                    label={"English " + key}
                    value={otherRates[key].En}
                    color="secondary"
                    onChange={e =>handleOtherRateChange(key, 'En', e.target.value)}
                    fullWidth
              />
            </Box>
          ))}
        </>
      )}
      
       
        <Box sx={{px:'5px'}}>
            <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Type</Button>
        </Box>
        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewRatingType