import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import { useState,useEffect } from "react"
import { tokens } from "../../../theme";
import axios from "axios"
import { getAuthentication } from "../../../auth"


import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AddNewRatingItem from "./AddNewRatingItem";
const API_URL = `https://dashapi.ingochat.com/`
const RatingTypeSchemaGQL = `
id
MainInfo{
  Title{
    Ar
    En
  }
  Description{
    Ar
    En
  }
}
RateConfig{
    isThereRateKeys
    RateKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
        Key3{
            Enabled
            Ar
            En
        }
        Key4{
            Enabled
            Ar
            En
        }
    }
    RateFrom
    OtherKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
    }
}
Infos{
    categoriesCount
    Count
    CreatedAt
}
`

const RatingCategorySchemaGQL = `
id
MainInfo{
    Title{
        Ar
        En
    }
    Description{
        Ar
        En
    }
    RatingType{
        ${RatingTypeSchemaGQL}
    }
}
Infos{
    Count
    CreatedAt
}
`
const RatingItemSchemaGQL = `
id
MainInfo{
    Title{
        Ar
        En
    }
    Description{
        Ar
        En
    }
    RatingType{
        ${RatingTypeSchemaGQL}
    }
    RatingCategory{
        ${RatingCategorySchemaGQL}
    }
}
MainImage{
    FilePath
}
RatingConfig{
    isThereRateKeys
    TotalRate
    RateFrom
    RateKeys{
        Key1{
            Enabled
            Value
            Ar
            En
        }
        Key2{
            Enabled
            Value
            Ar
            En
        }
        Key3{
            Enabled
            Value
            Ar
            En
        }
        Key4{
            Enabled
            Value
            Ar
            En
        }
    }
    OtherKeys{
        Key1{
            Enabled
            Value
            Ar
            En
        }
        Key2{
            Enabled
            Value
            Ar
            En
        }
    }
}
`

const FavouriteItems = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [ratingItems,setRatingItems] = useState([])
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)

    
    const HandleMainInfoSubmit = (titlear,titleen,descar,descen,typeid,catid,filepath) =>{
       AddNewItemFunc(titlear,titleen,descar,descen,typeid,catid,filepath) 
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents = {
        "ADD": <AddNewRatingItem  HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const AddNewItemFunc = async(titlear,titleen,descar,descen,typeid,catid,filepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewRatingItem(titlear:"${titlear}",titleen:"${titleen}",descar:"${descar}",descen:"${descen}",typeid:"${typeid}",catid:"${catid}",filepath:"${filepath}") {
                  ${RatingItemSchemaGQL}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewRatingItem.id !== null){
            fetchData()
          }
    }

  

    const handleNewBtnClick = () =>{
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleDeleteClick = async(type) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteRatingItem(id:"${type.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const handleEditClick = (type) =>{
        setModalStatus("EDIT")
        setModalOpen(true)
    }

    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
                GetAllRatingItems(limit:${rowsPerPage},offset:${page}){
                ${RatingItemSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
            setRatingItems(res.data.data.GetAllRatingItems)
            setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }

    useEffect(() => {
        fetchData()
        }, [rowsPerPage,page])


    return(
       <Box sx={{
            mt:2
       }}> 
       <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Item</Button>

                    <Box   sx={{display:isLoaderVisible === 'flex' ? 'flex' : 'none',justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>


            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Item Name (ar)</TableCell>
        <TableCell>Item Name (en)</TableCell>
        <TableCell>Cateogry</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    ratingItems.map(ctg => (
        <TableRow key={ctg.id}>
        <TableCell>{ctg.MainInfo.Title.Ar}</TableCell>
        <TableCell>{ctg.MainInfo.Title.En}</TableCell>
        <TableCell>{ctg.MainInfo.RatingCategory.MainInfo.Title.Ar} - {ctg.MainInfo.RatingCategory.MainInfo.Title.En}</TableCell>
        <TableCell>{ctg.MainInfo.RatingType.MainInfo.Title.Ar} - {ctg.MainInfo.RatingType.MainInfo.Title.En}</TableCell>
        <TableCell>
         
            <IconButton color="error" onClick={() => handleDeleteClick(ctg)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={ratingItems.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
       </Box>
    )
}
export default FavouriteItems