import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,TableContainer,TableBody,TableCell,TableRow,Table,TableHead,TablePagination} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const CityConfig = ({Country,enableCityEdit,EditModalConfigStatus,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)


  const [cities , setCities] = useState([])


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
}

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
}

 
const DeleteCity = async(city) =>{
    try {
       await axios.post(API_URL, {
        query: `
          mutation {
            DeleteCity(id:"${city.id}") 
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });
      fetchData()


    } catch (error) {
    }
}
  const fetchData = async () => {


    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
            getCitiesOfCountry(id:"${Country.id}"){
                id
                CityName{
                    ar
                    en
                }
                Enable
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCities(res.data.data.getCitiesOfCountry)
    }
  }
  catch(err){

  }
}

useEffect(() => {
    fetchData()
    }, []);

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  height:"90%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{Country.CountryName.en + ' Cities '} </Typography>

      <Formik
       
> 


{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
      <Box>
      <Button sx={{mx:1}} variant="contained" onClick={()=>{EditModalConfigStatus('CITYADD')}} color="secondary">Add New City</Button>
      <Button sx={{mx:1}} variant="contained" onClick={()=>{EditModalConfigStatus('EDIT')}} color="warning">Edit Country</Button>

    </Box>
      <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>City Name (ar)</TableCell>
        <TableCell>City Name (en)</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    cities.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.CityName.ar}</TableCell>
        <TableCell>{lng.CityName.en}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => enableCityEdit(lng)}> 
                <EditIcon />
            </IconButton>
          
            <IconButton color="error" onClick={() => DeleteCity(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={cities.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
       
      
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default CityConfig;