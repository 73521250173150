import React, { useState } from 'react'
import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { CheckOutlined, CloseOutlined } from '@mui/icons-material';

const AccountDataInfo = ({Account,handleToggleBanState}) => {

    const [banState,setBanState] = useState(Account.AccountStatus.Banned)
    const handleSaveBtn = () => {
        handleToggleBanState(!banState)
        setBanState(!banState)

    }
  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        gap:2,
        flexWrap:"wrap",
        mt:3
     }}>
         <Box sx={{
            width: "48%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Last Login: <span style={{color:"#4cceac"}}>{new Date(Account.LastLogin.Date).toLocaleString()}</span></Typography>
        </Box>
        <Box sx={{
            width: "48%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Credits: <span style={{color:"#4cceac"}}>{Account.AccountData.Credits}</span></Typography>
        </Box>
        <Box sx={{
            width: "30%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Account Main Infos</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",p:1,gap:1.5}}>
            <Typography variant="h6">Full Name: {Account.AccountData.MainInfo.FullName !== '' ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.FullName}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Gender: {(Account.AccountData.MainInfo.Gender !== '' && Account.AccountData.MainInfo.Gender !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Gender}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Marital Status: {(Account.AccountData.MainInfo.MaritalStatus !== '' && Account.AccountData.MainInfo.MaritalStatus !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.MaritalStatus}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Birthdate: {(Account.AccountData.MainInfo.Birthdate !== '' && Account.AccountData.MainInfo.Birthdate !== null ) ? <span style={{color:"#4cceac"}}>{new Date(Account.AccountData.MainInfo.Birthdate).toLocaleDateString()}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Location 1: {(Account.AccountData.MainInfo.Country.OriginCountry.Country !== '' && Account.AccountData.MainInfo.Country.OriginCountry.Country !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Country.OriginCountry.Country.CountryName.en}  {Account.AccountData.MainInfo.Country.OriginCountry.City !== null && `- ${Account.AccountData.MainInfo.Country.OriginCountry.City.CityName.en}` }</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Location 2: {(Account.AccountData.MainInfo.Country.InAnotherCountryLive  && Account.AccountData.MainInfo.Country.OtherCountry.Country !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Country.OtherCountry.Country.CountryName.en}  {Account.AccountData.MainInfo.Country.OtherCountry.City !== null && `- ${Account.AccountData.MainInfo.Country.OtherCountry.City.CityName.en}` }</span>: <span style={{color:"#4cceac",marginLeft:2}}>There is no other location</span> }</Typography>
            <Typography variant="h6">Job: {(Account.AccountData.MainInfo.Job.isWorking !== '' && Account.AccountData.MainInfo.Job.isWorking !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Job.isWorking ? Account.AccountData.MainInfo.Job.WorkName : `Not Working`}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Study: {(Account.AccountData.MainInfo.Study.isStudying !== '' && Account.AccountData.MainInfo.Study.isStudying !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Study.isStudying ? Account.AccountData.MainInfo.Study.StudyName : `Not Studying`}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Bio: {(Account.AccountData.MainInfo.Bio !== '' && Account.AccountData.MainInfo.Bio !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.Bio}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>
            <Typography variant="h6">Hope From Ingo: {(Account.AccountData.MainInfo.HopeFromIngo !== '' && Account.AccountData.MainInfo.HopeFromIngo !== null ) ? <span style={{color:"#4cceac"}}>{Account.AccountData.MainInfo.HopeFromIngo.Item.en}</span>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span> }</Typography>

            </Box>
            
            
        </Box>
        <Box sx={{
            width: "30%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Account Info</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",p:1,gap:1.5}}>
            <Typography variant="h6">Email:
            {Account.AccountType === 'standard' && Account.StandardAccount.Credentials.Email !== '' ? <a style={{color:"#4cceac"}} href={`mailto:${Account.StandardAccount.Credentials.Email}`}>{Account.StandardAccount.Credentials.Email}</a>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span>}   
            {Account.AccountType === 'google' &&( Account.GoogleAccount.Email !== '' ? <a style={{color:"#4cceac"}} href={`mailto:${Account.GoogleAccount.Email}`}>{Account.GoogleAccount.Email}</a>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span>)}   
            {Account.AccountType === 'facebook' && (Account.FacebookAccount.Email !== '' ? <a style={{color:"#4cceac"}} href={`mailto:${Account.FacebookAccount.Email}`}>{Account.FacebookAccount.Email}</a>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span>)}   
            {Account.AccountType === 'apple' && (Account.AppleAccount.Email !== '' ? <a style={{color:"#4cceac"}} href={`mailto:${Account.AppleAccount.Email}`}>{Account.AppleAccount.Email}</a>: <span style={{color:"red",marginLeft:2}}>Not Set Yet</span>)}   
             
             </Typography>
            <Typography variant="h6">Account Type: <span style={{color:"#4cceac"}}>{Account.AccountType }</span></Typography>
            <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Verified: {Account.StandardAccount.VerificationStatus.Verified  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
            <Typography variant="h6">Created At: {Account.Infos.CreatedAt !== '' &&  <span style={{color:'#4cceac'}}>{new Date(Account.Infos.CreatedAt).toLocaleString()}</span>  }</Typography>
            <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Activated: {Account.AccountStatus.Activated  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
            <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Banned: <Switch color='secondary' checked={banState} onChange={() => handleSaveBtn(!banState)} /></Typography>
            


            </Box>
            
            
        </Box>
        <Box sx={{
            width: "30%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Account App Config</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",p:1,gap:1.5,mb:3}}>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Notification: {Account.AppConfig.Notification  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Dark Mode: {Account.AppConfig.DarkMode  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">App Language: <span style={{color:"#4cceac"}}>{Account.AppConfig.AppLanguage }</span> </Typography>
            </Box>
            <Typography variant="h5">Account Privacy Settings</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",p:1,gap:1.5,mb:3}}>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Add To Chat Room Without Permission: {Account.AccountData.PrivacySettings.AllowAddToChatRoomWithoutPermission  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Allow Messages From Other Users: {Account.AccountData.PrivacySettings.AllowUserSendMessagesToMe  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
                <Typography sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:1}} variant="h6">Use Nickname: {Account.AccountData.PrivacySettings.UseNickName  ? <CheckOutlined color='secondary' />: <CloseOutlined color='error'/> }</Typography>
            </Box>
        </Box>
        <Box sx={{
            width: "48%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Interests</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:2,p:1}}>
                {Account.AccountData.ContentSpecification.Interests.map((intrst)=>{
                    return <span style={{border:1,display:"inline",backgroundColor:"#1e5245",padding:"5px",fontSize:"14px",borderRadius:"5px"}}>{intrst.InterestName.en} - {intrst.InterestName.ar}</span>
                })}
            </Box>
        </Box>

        <Box sx={{
            width: "48%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Content Languages</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:2,p:1}}>
                {Account.AccountData.ContentSpecification.ContentLanguages.map((cntn)=>{
                    return <span style={{border:1,display:"inline",backgroundColor:"#1e5245",padding:"5px",fontSize:"14px",borderRadius:"5px"}}>{cntn.CountryName.en} - {cntn.CountryName.ar}</span>
                })}
            </Box>
        </Box>

        <Box sx={{
            width: "100%",
            border:1,
            borderRadius:1,
            p:1
        }}>
            <Typography variant="h5">Privacy Setting Type: <span style={{color:"#4cceac"}}>{Account.AccountData.PrivacySettings.PrivacySettingsType  ?  Account.AccountData.PrivacySettings.PrivacySettingsType : 'Not Set Yet'}</span></Typography>
        </Box>
        {
            Account.AccountData.PrivacySettings.PrivacySettingsType === 'specified' && Account.AccountData.PrivacySettings.SpecifiedSettings && <Box sx={{
                width: "100%",
                border:1,
                borderRadius:1,
                p:1,
               
                
            }}>
                 <Typography variant="h5">Privacy Specified Settings</Typography>
            <hr style={{color:"white"}}/>
            <Box sx={{ display:'flex',
                flexDirection:'row',
                flexWrap:'wrap',}}>

                {Object.entries(Account.AccountData.PrivacySettings.SpecifiedSettings).map(([key, value]) => (
                    <Typography sx={{mr:5,mb:2}} variant="h5">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}: <span style={{color:"#4cceac"}}>{value}</span></Typography>

                  
                ))}
                </Box>
            </Box>
        }
     </Box>
     
  )
}

export default AccountDataInfo