import React, { useState } from 'react'
import { Box,Tab,Tabs,TableContainer,TablePagination,Table,TableHead,TableRow,TableCell,TableBody,Dialog,DialogActions,DialogContent,DialogTitle, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { CheckOutlined, Close, CloseOutlined } from '@mui/icons-material';
import { tokens } from '../../../theme';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import AccountAccountCover from './AccountAccountCover';
import AccountAwardHolders from './AccountAwardHolder';
import AccountColorPallets from './AccountColorPallet';
const TabPanel = (props) => {
    const { children, value, index } = props;
  
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box  p={3}>{children}</Box>}
      </div>
    );
  }
const AccountStore = ({Account}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tabValues,setTabValue] = useState(0)
    const [selectedItem,setSelectedItem] = useState(null)

    const handleClosePreview = () => {
        setSelectedItem(null);
    }
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"center",
            gap:2,
            flexWrap:"wrap",
            mt:3}}>
            <Dialog open={!!selectedItem} onClose={handleClosePreview} maxWidth="md">
            <DialogActions>
          <IconButton onClick={handleClosePreview}><Close /></IconButton>
        </DialogActions>
        <DialogContent>
       
        </DialogContent>
        
      </Dialog>
      <Box sx={{width:"100%"}}>

      <Tabs   indicatorColor="secondary"  textColor="secondary" value={tabValues} onChange={handleChange} >
            <Tab label="Accounts Covers" sx={{fontSize:'18px'}} />
            <Tab label="Award Holders" sx={{fontSize:'18px'}} />
            <Tab label="Color Pallets" sx={{fontSize:'18px'}} />
        </Tabs>
            <Box sx={{width:"100%"}}>
            <TabPanel  value={tabValues} index={0}>
                <AccountAccountCover  Account={Account} />
            </TabPanel>
            <TabPanel  value={tabValues} index={1}>
                <AccountAwardHolders  Account={Account} />
            </TabPanel>
            <TabPanel  value={tabValues} index={2}>
                <AccountColorPallets  Account={Account} />
            </TabPanel>
            </Box>
           
        
      </Box>
    
        </Box>
        
    )
}

export default AccountStore