import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import AddNewWarning from "./AddNewWarning";
import ShowDetails from "./ShowDetails";



const API_URL = `https://dashapi.ingochat.com/`

const DashboardWarningGQLSchema = `
id
Account{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
Text{
    En
    Ar
}
CreatedAt
`

const DashboardWarning = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [dashboarWarningCount,setDashboardWarningCount] = useState(0)
    const [dashboardWarning,setDashboardWarning] = useState([])
    const [selectedItem,setSelectedItem] = useState(null)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const HandleMainInfoSubmit = (account,en,ar) =>{
        AddNewWarningFunc(account,en,ar)
    }

    const ModalComponents = {
        "ADD": <AddNewWarning NewItem={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "DTL": <ShowDetails details={selectedItem} closingModal={handleCloseModal}/>
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }
  
    const showDetail = (item) =>{
        setSelectedItem(item)
        setModalStatus("DTL")
        setModalOpen(true)
    }

    const handleNewBtnClick = () =>{
        setModalStatus("ADD")
        setModalOpen(true)
    }

    const AddNewWarningFunc = async (account,en,ar) =>{
        try{
            const res = await axios.post(API_URL,{
                query: `mutation{
                    SendNewWarning(id:"${account}",en:"${en}",ar:"${ar}"){
                        ${DashboardWarningGQLSchema}
                    }
                }`
            },{
                headers:{
                    token:`Bearer ${getAuthentication()}`
                }
            })

            if(res.data.data.SendNewWarning.id){
                fetchData()
            }
        }
        catch(err){

        }
    }
    
    const GetDashboardWarningCount = async () =>{
        try{
          const res = await axios.post(API_URL,{
            query:`query{
              GetStaticNumber(key:"DashboardWarning"){
                Value
              }
            }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setDashboardWarningCount(res.data.data.GetStaticNumber.Value)          
          }
        }
        catch(err){}
      }

      const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetDashboardWarnings(limit:${rowsPerPage},offset:${page}){
                        ${DashboardWarningGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
          setDashboardWarning(res.data.data.GetDashboardWarnings)
          setLoaderVisibility('none')
          GetDashboardWarningCount()
        }
      }
      catch(err){
  
      }
    }
    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
   
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Dashboard Warning`} subtitle={`Display & Add Dashboard Warning`} />

            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                <Box sx={{
                    display:"flex",
                    gap:1
                }}>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Item</Button>


                </Box>

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="50vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Account</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    dashboardWarning.map((itm,index) => (
        <TableRow key={`PaymentsLog${index}`}>
        <TableCell>{itm.Account.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{itm.CreatedAt}</TableCell>
        <TableCell>
        <IconButton onClick={()=>showDetail(itm)}>
            <RemoveRedEyeOutlined />
        </IconButton>
        </TableCell>
       
        
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={dashboarWarningCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default DashboardWarning