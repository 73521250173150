import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const InterestSchemaGQL = `
id
InterestName{
  ar
  en
}
`

const AddNewHopeItems = ({NewPost,Post,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [allInterests,setAllInterests] = useState([])

  const [text,setText] = useState(NewPost?'':Post.MainInfo.Standard.Text)
  const [isEmbed,setIsEmbed] = useState(NewPost?false:Post.MainInfo.Standard.isEmbed)
  const [embedType,setEmbedType] = useState(NewPost?'':Post.MainInfo.Standard.EmbedObj.Type)
  const [embedLink,setEmbedLink] = useState(NewPost?'':Post.MainInfo.Standard.EmbedObj.Link)
  const [images,setImages] = useState(NewPost?[]:Post.MainInfo.Standard.Images)
  const [interests,setInterests] = useState(NewPost?[]:Post.MainInfo.Interests)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')

  const HandleSubmitBtn = () =>{
      let imgs = images.join(',')
      let intrst = interests.map((interest) => interest.id).join(',')
      HandleMainInfoSubmit(text,isEmbed,embedType,embedLink,imgs,intrst)
      closingModal()
  }

 
  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setImages([...images, res.data.name])
    setMSG('File has uploaded successfully')

   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }

  const moveItemToTarget = (item) => {
    setAllInterests(allInterests.filter((i) => i !== item));
    setInterests([...interests, item]);
  }

  const moveItemToSource = (item) => {
    setInterests(interests.filter((i) => i !== item));
    setAllInterests([...allInterests, item]);
  };

  
  const fetchData = async () => {
    try{
      const res = await axios.post(API_URL,{
            query:`query{
              GetInterestItems{
                ${InterestSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          let allItems = res.data.data.GetInterestItems;
          let newInterests = allItems.filter((item) => !interests.some((interest) => interest.id === item.id))
          setAllInterests(newInterests);
        }
  }
  catch(err){

  }
}

useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewPost ? "Add New Post" : "Edit Post"}</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
          display:"flex",
          justifyContent:"space-around"
        }}>
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-around',
            width:"58%"
        }}>
                <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={text}
                    onChange={(e)=>setText(e.target.value)}
                    label="Post content text ..."
                    color="secondary"
                    multiline
                    rows={5} 
                    />
                <Box sx={{mt:2}}>
                  <Switch checked={isEmbed} color="secondary" onChange={(e)=>setIsEmbed(e.target.checked)}   />
                  Enable Embed
                </Box>
                <Box sx={{
                  display: isEmbed? "flex" : "none",
                  justifyContent:"space-around",
                  
                }}>
                  <TextField 
                    sx={{
                        mt:3,
                        width:"48%"
                    }}
                    variant="filled"
                    type="text"
                    value={embedType}
                    onChange={(e)=>setEmbedType(e.target.value)}
                    label="Embed Type ..."
                    color="secondary"
                    />
                    <TextField 
                    sx={{
                        mt:3,
                        width:"48%"
                    }}
                    variant="filled"
                    type="text"
                    value={embedLink}
                    onChange={(e)=>setEmbedLink(e.target.value)}
                    label="Embed Link ..."
                    color="secondary"
                    />
                </Box>

                <Box sx={{
                  mt:2,
                  height:"30vh",
                  overflowY:"scroll",
                  border:1,
                  borderColor:"#aaa",
                  p:1
                }}>

<Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>

        </Box>
        <Typography sx={{textAlign:'center',mt:1,mb:1}} color="error">{msg}</Typography>
        <Divider />
                  <Box sx={{
                    display:"flex",
                    flexDirection:"column",
                    px:4
                  }}>

                    {
                      images.map(img=>(
                        <>
                        <Box sx={{
                          mt:2,
                          display:"flex",
                          justifyContent:"space-around"
                        }}>
                            <Typography variant="p">{img}</Typography>
                            <Box>
                            <IconButton color="secondary" onClick={() =>window.open('https://cloud.ingochat.com/'+img, '_blank') }> 
                              <RemoveRedEyeIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() =>setImages(images.filter((image) => image !== img)) }> 
                              <DeleteIcon />
                            </IconButton>

                              </Box>
                          </Box>
                          <Divider />
                          </>
                      ))
                    }

                  </Box>

                
                </Box>
                <Typography>{images.length} has been uploaded</Typography>


                    
        </Box>

        <Box sx={{
          width:'38%',
          mt:3,
          display:"flex",
          justifyContent:"space-around"
        }}>
          <List sx={{
            border:1,
            px:1,
            borderColor:"#ccc",
            borderRadius:1,
            width:'49%',
            height:"50vh",
            overflowY:"scroll"
          }}>
          <Typography variant="h5" sx={{textAlign:"center"}}>Interests</Typography>
          {allInterests.map((item) => (
          <ListItem sx={{
            borderBottom:1,
            borderColor:"#aaa"

          }} key={item.id}>
            <Typography sx={{
              width:"80%",
            }} variant="h6">
              {item.InterestName.ar} - {item.InterestName.en}
            </Typography>
            <IconButton color="secondary" onClick={() => moveItemToTarget(item)}><KeyboardArrowRightIcon/></IconButton>
          </ListItem>
        ))}
      
      

          </List>

          <List  sx={{
            border:1,
            borderColor:"#ccc",
            px:1,
            borderRadius:1,

            width:'49%',
            height:"50vh",
            overflowY:"scroll"
          }}>
                      <Typography variant="h5" sx={{textAlign:"center"}}> Choosen Interests</Typography>

        {interests.map((item) => (
          <ListItem sx={{
            borderBottom:1,
            borderColor:"#aaa"

          }} key={item.id}>
 <Typography sx={{
              width:"80%",
            }} variant="h6">
              {item.InterestName.ar} - {item.InterestName.en}
            </Typography>            <IconButton color="error" onClick={() => moveItemToSource(item)}><KeyboardArrowLeftIcon/></IconButton>
          </ListItem>
        ))}
      </List>
        </Box>
      
        
    
            
              
        </Box>
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewPost? `Add New Post` : `Edit Post`}</Button>

        </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default AddNewHopeItems;