import React, { useState } from 'react'
import { Box,TableContainer,TablePagination,Table,TableHead,TableRow,TableCell,TableBody,Dialog,DialogActions,DialogContent,DialogTitle, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { CheckOutlined, Close, CloseOutlined } from '@mui/icons-material';
import { tokens } from '../../../theme';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';

const AccountAwardHolders = ({Account}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);



    const [awardHolders,setAwardHolders] = useState(Account.AccountData.ContentSpecification.AwardHolder.OwnedAwardHolder? Account.AccountData.ContentSpecification.AwardHolder.OwnedAwardHolder: [] )
    const [selectedItem,setSelectedItem] = useState(null)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handlePreview = () =>{
        
    }

    const handleClosePreview = () => {
        setSelectedItem(null);
    }
    return (
        <Box>
            <Dialog open={!!selectedItem} onClose={handleClosePreview} maxWidth="md">
            <DialogActions>
          <IconButton onClick={handleClosePreview}><Close /></IconButton>
        </DialogActions>
        <DialogContent>
       
        </DialogContent>
        
      </Dialog>
      <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[500],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Item Name (ar)</TableCell>
        <TableCell>Item Name (en)</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    awardHolders.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.Title.Ar}</TableCell>
        <TableCell>{itm.Title.En}</TableCell>
        
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={awardHolders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
        
    )
}

export default AccountAwardHolders