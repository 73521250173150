import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { Category } from "@mui/icons-material";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`


const AccountCoverCategoriesSchemaGQL = `
id
Name{
    ar
    en
}
`

const AddNewAccountCover = ({NewCover,Cover,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ar,setAr] = useState(NewCover?'':Cover.MainInfo.Name.ar)
  const [en,setEn] = useState(NewCover?'':Cover.MainInfo.Name.en)
  const [accountCoverCategories,setAccountCoverCategories] = useState([])
  const [coverType,setCoverType] = useState(NewCover?'image':Cover.CoverType)
  const [coverCategory,setCoverCategory] = useState(NewCover?'':Cover.MainInfo.Category.id)
  const [filepath,setFilePath] = useState(NewCover?'':Cover.MainInfo.CoverImage.FilePath)
  const [price,setPrice] = useState(NewCover?'':Cover.Price)
  const [hex,setHex] = useState(NewCover?'':Cover.MainInfo.CoverColor.Hex)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')
  const HandleSubmitBtn = () =>{ 
    HandleMainInfoSubmit(ar,en,coverType,coverCategory,filepath,price,hex)
    closingModal()
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }

  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setFilePath(res.data.name)
    setMSG('File has uploaded successfully')
   }

  const getCategoriesData = async () => {
    try{ 
      const res = await axios.post(API_URL,{
            query:`query{
                getAccountCoverCategories{
                ${AccountCoverCategoriesSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
      if(res.status === 200){
        setAccountCoverCategories(res.data.data.getAccountCoverCategories)
      }
  }
  catch(err){}
}
  
  
useEffect(() => {
  getCategoriesData()
  }, [])



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewCover ? "Add New Cover" : "Edit Cover"}</Typography>
      {!NewCover ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Cover.MainInfo.Name.ar + ' - ' + Cover.MainInfo.Name.en}</Typography>):(<></>) }
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Cover Title (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Cover Title (ar) ..."
                    color="secondary"
                    />
        </Box>
        <Box sx={{mt:1,p:1,px:2}}>
        <InputLabel color="secondary" id="demo-simple-select-label414">Select Category ...</InputLabel>
        <Select variant="filled"  value={coverCategory} onChange={(e)=>setCoverCategory(e.target.value)}   labelId="demo-simple-select-label414"  color="secondary" fullWidth label="Select Category ..." >
        {accountCoverCategories.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.Name.ar} - {cat.Name.en}
                      </MenuItem>
                  ))}
        </Select>
        </Box>
        <Box sx={{p:1,px:2}}>
        <InputLabel color="secondary" id="demo-simple-select-label">Cover Type  </InputLabel>
        <Select variant="filled"  value={coverType} onChange={(e)=>setCoverType(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Category ..." >
          <MenuItem key="cvrtp1" value="image">Image</MenuItem>         
          <MenuItem key="cvrtp2" value="color">Color</MenuItem>         
        </Select>
        </Box>

          {
            coverType === 'image' ? (<Box sx={{p:1,px:2}}>
              <Box sx={{
                      mt:1,
                      display:'flex',
                      justifyContent:'space-around'
                  }}>
                  <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
                  <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>
              </Box>
              <Box>
              <Typography sx={{textAlign:'center',mt:1}} color="error">{msg}</Typography>
              </Box>
            </Box>) : ( <Box sx={{p:1,px:2}}>

<TextField 
            sx={{
                mt:3,
            }}
            fullWidth
            variant="filled"
            type="text"
            value={hex}
            onChange={(e)=>setHex(e.target.value)}
            label="Hex Color (Without #) ..."
            color="secondary"
            />
            </Box>
)
          }
        
       
        
        <Box sx={{p:1,px:2}}>
        <TextField 
                    sx={{
                        mt:3,
                    }}
                    fullWidth
                    variant="filled"
                    type="text"
                    value={price}
                    onChange={(e)=>setPrice(e.target.value)}
                    label="Price"
                    color="secondary"
                    />
        </Box>
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewCover? `Add New Cover` : `Edit Cover`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewAccountCover;