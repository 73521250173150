import { useState,useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme,Divider} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";



import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from '@mui/icons-material/Person';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import TranslateIcon from '@mui/icons-material/Translate';
import InterestsIcon from '@mui/icons-material/Interests';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import PublicIcon from '@mui/icons-material/Public';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import MessageIcon from '@mui/icons-material/Message';
import BlockIcon from '@mui/icons-material/Block';
import PeopleIcon from '@mui/icons-material/People';
import Face5Icon from '@mui/icons-material/Face5';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FilterIcon from '@mui/icons-material/Filter';
import TokenIcon from '@mui/icons-material/Token';
import PaymentsIcon from '@mui/icons-material/Payments';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({Userpermissions}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");


  return (
    <Box
      sx={{
        position:"sticky",
        top:"0",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  INGO 
                </Typography>
              
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}

{!isCollapsed && (
     <Box
     display="flex"
     justifyContent="space-between"
     alignItems="center"
     ml="15px"
   >
    <Typography variant="h6" color="secondary">
    Powered by ULDASH
  </Typography>
  </Box>

)}
            
          </MenuItem>

         

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            <Item
              title="Dashboard"
              to="/Dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            
            {Userpermissions.accounts ? (
             <>
              <Item key={'l1'} title="Accounts" to="/Accounts" icon={<PersonIcon />} selected={selected} setSelected={setSelected} />
             </>
            ):(<></>) } 

            {
              Userpermissions.fakeAccount ? (
                <Item key={'l1'} title="Fake Accounts" to="/fakeAccounts" icon={<Face5Icon />} selected={selected} setSelected={setSelected} />

              ):(<></>)
            }
            {Userpermissions.accountrelations ? (
             <>
              <Item key={'la12'} title="Accounts Relations" to="/accountRelations" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
             </>
            ):(<></>) } 
            <Divider />

             {Userpermissions.posts ? (
             <>
              <Item key={'la11'} title="Posts" to="/Posts" icon={<MessageIcon />} selected={selected} setSelected={setSelected} />
             </>
            ):(<></>) } 
            {
              Userpermissions.rating ? (
                <>
                  <Item key={'la12'} title="Rating" to="/Ratings" icon={<StarHalfIcon />} selected={selected} setSelected={setSelected} />
                </>
              ):(<></>)
            }
             {
              Userpermissions.ad ? (
                <>
                  <Item key={'la129'} title="Ads" to="/Ads" icon={<AdUnitsIcon />} selected={selected} setSelected={setSelected} />
                </>
              ):(<></>)
            }
            <Divider />


            {Userpermissions.contentLanguages ? (
              <Item key={'l2'} title="Content Languages" to="/ContentLanguages" icon={<TranslateIcon />} selected={selected} setSelected={setSelected} />
            ):(<></>)}

              {Userpermissions.interestsItems ? (
              <Item key={'l3'} title="Interests Items" to="/InterestItems" icon={<InterestsIcon />} selected={selected} setSelected={setSelected} />
            ):(<></>)}

              {Userpermissions.hopesFromIngo ? (
              <Item key={'l4'} title="Hopes From Ingo" to="/HopesFromIngo" icon={<DensitySmallIcon />} selected={selected} setSelected={setSelected} />
            ):(<></>)}

              {Userpermissions.country ? (
              <Item key={'l5'} title="Country" to="/countries" icon={<PublicIcon />} selected={selected} setSelected={setSelected} />
            ):(<></>)}

             
                 {Userpermissions.favouriteItem ?(
                <Item key={'l7'} title='Favourite Items' to='/favouriteItems' icon={<FavoriteIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}

              {Userpermissions.staticText?(
                <Item key={'l8'} title='Static Text' to='/staticText' icon={<TextFieldsIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}

              {Userpermissions.blockreasons?(
                <Item key={'l9'} title='Block Reasons' to='/blockreasons' icon={<BlockIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}
              {Userpermissions.profanity?(
                <Item key={'l91'} title='Profanity List' to='/Profanity' icon={<RemoveCircleOutlineIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}
              {Userpermissions.chatroomtypes ? (
                  <Item key={'fd1R'} title='Chat Room Types' to='/chatroomtypes' icon={<BorderClearIcon />} selected={selected} setSelected={setSelected} />
                  ): (<></>)
              }

              {Userpermissions.chatroomreportstypes ? (
                  <Item key={'fd2R'} title='Chat Room Reports Types' to='/chatroomreportstypes' icon={<ReportGmailerrorredIcon />} selected={selected} setSelected={setSelected} />
                  ):(<></>)
                }
              <Divider />
              
              {Userpermissions.creditspackage ? (
                <Item key={'l12a'} title='Credits Packages' to='/CreditsPackage' icon={<TokenIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              }
              
              {Userpermissions.paymentslog ? (
                <Item key={'l12ba'} title='Payments Log' to='/PaymentsLog' icon={<PaymentsIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              }
            <Divider />
            {Userpermissions.accountCovers ?(
                <Item key={'l6'} title='Account Covers' to='/accountCovers' icon={<BurstModeIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}
            

            {Userpermissions.awardholder?(
                <Item key={'l10'} title='Award Holders' to='/AwardHolders' icon={<EmojiEventsIcon />} selected={selected} setSelected={setSelected} />
              ):(<></>)}
              {Userpermissions.colorpallet ? (
                <Item key={'l11'} title='Color Pallets' to='/ColorPallets' icon={<ColorLensIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              }
              
              {Userpermissions.imagepackage ? (
                <Item key={'l12'} title='Image Packages' to='/ImagePackages' icon={<FilterIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              } 
              {Userpermissions.chatroombackground ? (
                <Item key={'l13'} title='Chat Room Backgrounds' to='/ChatRoomBackgrounds' icon={<WallpaperIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              } 
              {Userpermissions.chatroomavatar ? (
                <Item key={'l14'} title='Chat Room Avatars' to='/ChatRoomAvatars' icon={<RememberMeIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              } 
              {Userpermissions.chatroomtopnav ? (
                <Item key={'l15'} title='Chat Room Top Navs' to='/ChatRoomTopNavs' icon={<LinearScaleIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              } 
            <Divider />
              
              

              {
                Userpermissions.chatroomreports? 
                (
                  <Item key={'fd3R'} title='Chat Room Reports' to='/chatroomreports' icon={<ReportIcon />} selected={selected} setSelected={setSelected} />
                  ):(<></>)
              }
              {
                Userpermissions.dashboardwarning?
                (
                  <Item key={'fd3R1'} title='Dashboard Warnings' to='/dashboardwarnings' icon={<SecurityUpdateWarningIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              }
               {
                Userpermissions.appupdate?
                (
                  <Item key={'fd3R2'} title='App Update' to='/appupdate' icon={<SecurityUpdateIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              }
              {Userpermissions.feedback ? (
                <Item key={'fd12'} title='Feedbacks' to='/Feedbacks' icon={<FeedbackIcon />} selected={selected} setSelected={setSelected} />
                ):(<></>)
              } 

             
         
           
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;