import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import AddNewColorPallet from "./AddNewColorPallet";
import ManageDiscount from "./ManageDiscount";
import { RemoveRedEye } from "@mui/icons-material";
import ColorPalletRelatedAccount from "./ColorPalletRelatedAccount";


const API_URL = `https://dashapi.ingochat.com/`


const ColorPalletsGQLSchema = 
`id
ColorHex
Price
Title{
    En
    Ar
}
Discount{
    isDiscount
    DiscountType
    Amount
}
Enabled
Priority
CreatedAt
`

const ColorPallets = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [colorPallets,setColorPallets] = useState([])
    const [selectedItem,setSelectedItem] = useState(null)

    const HandleMainInfoSubmit = (hexcolors,price,en,ar,isdiscount,discounttype,discountamount,priority) =>{
        isNewItem ? AddNewColorPalletFunc(hexcolors,price,en,ar,isdiscount,discounttype,discountamount,priority) : EditColorPalletFunc(hexcolors,price,en,ar,isdiscount,discounttype,discountamount,priority)
    }

    const handleChangeDiscountSubmit = (isdiscount,discounttype,discountamount) =>{
        changeDiscountForAll(isdiscount,discounttype,discountamount)
    }
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewColorPallet NewItem={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewColorPallet NewItem={false} Item={selectedItem} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "DSC" : <ManageDiscount HandleMainInfoSubmit={handleChangeDiscountSubmit} closingModal={handleCloseModal} />,
        "ACC" : <ColorPalletRelatedAccount Item={selectedItem} closingModal={handleCloseModal} />
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewItem(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleManageDiscountBtnClick = () =>{
        setModalOpen(true)
        setModalStatus('DSC')

    }

    const handleEditClick = (interest) =>{
        setSelectedItem(interest)
        setModalStatus("EDIT")
        setIsNewItem(false)
        setModalOpen(true)
    }
    
    const showAccountHoldingThis = (item) =>{
        setSelectedItem(item)
        setModalStatus("ACC")
        setModalOpen(true)
    }
  

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteColorPallet(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                  GetColorPallets(limit:${rowsPerPage},offset:${page}){
                        ${ColorPalletsGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setColorPallets(res.data.data.GetColorPallets)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }
    const handleChangeEnable = async(state,item) =>{ 
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                ToggleEnableColorPallet(id:"${item.id}",enable:${state}) {
                  ${ColorPalletsGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.ToggleEnableColorPallet.id !== null){
            fetchData()
          }
    }

    const changeDiscountForAll = async(isdiscount,discounttype,discountamount) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                ManageDiscountToAllColorPallet(isdiscount:${isdiscount},discounttype:"${discounttype}",discountamount:${discountamount})
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
        if(res.data.data.ManageDiscountToAllColorPallet){
            fetchData()
        }
    }

    const AddNewColorPalletFunc = async(hexcolors,price,en,ar,isdiscount,discounttype,discountamount,priority) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewColorPallet(hexs:"${hexcolors}",price:${price},en:"${en}",ar:"${ar}",isdiscount:${isdiscount},discounttype:"${discounttype}",discountamount:${discountamount},priority:${priority}) {
                  ${ColorPalletsGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewColorPallet.id !== null){
            fetchData()
          }
    }

    const EditColorPalletFunc = async(hexcolors,price,en,ar,isdiscount,discounttype,discountamount,priority) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                  EditColorPallet(id:"${selectedItem.id}",hexs:"${hexcolors}",price:${price},en:"${en}",ar:"${ar}",isdiscount:${isdiscount},discounttype:"${discounttype}",discountamount:${discountamount},priority:${priority}){
                        ${ColorPalletsGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditColorPallet.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Color Pallets`} subtitle={`Add, modify, delete color pallets and manage discounts`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                <Box sx={{
                    display:"flex",
                    gap:1
                }}>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Item</Button>
                <Button variant="contained" onClick={handleManageDiscountBtnClick} color="secondary">Manage Discounts</Button>


                </Box>

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Item Name (ar)</TableCell>
        <TableCell>Item Name (en)</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Enabled</TableCell>
        <TableCell>Discount</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    colorPallets?.map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.Title.Ar}</TableCell>
        <TableCell>{itm.Title.En}</TableCell>
        <TableCell>{itm.CreatedAt}</TableCell>
        <TableCell><Switch checked={itm.Enabled} color="secondary" onChange={(e)=>{handleChangeEnable(e.target.checked,itm)}}/></TableCell>
        <TableCell>
            <Box sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                alignContent:"center",
                gap:1
            }}>
            {itm.Discount.isDiscount ? <DoneIcon color="secondary" /> : <CloseIcon color="error" />} {itm.Discount.isDiscount && itm.Discount.Amount}{(itm.Discount.isDiscount && itm.Discount.DiscountType === "PERCENTAGE") && "%"}
            </Box>
            </TableCell>

        <TableCell>
            <IconButton color="warning" onClick={()=>showAccountHoldingThis(itm)}>
                <RemoveRedEye />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleEditClick(itm)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(itm)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={colorPallets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default ColorPallets