import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { Category } from "@mui/icons-material";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const FeedbackDetail = ({feedback,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [item,setItem] = useState(feedback)




  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Feedback</Typography>
<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{item.Account.AccountData.MainInfo.FullName}</Typography>
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
            <Box>
                <p>
                    {item.Text}
                </p>
            </Box>

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default FeedbackDetail