import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import AddNewChatRoomTypes from "./AddNewChatRoomTypes";
import ManageDiscount from "./ManageDiscount";
import { RemoveRedEye } from "@mui/icons-material";


const API_URL = `https://dashapi.ingochat.com/`


const ChatRoomTypesGQLSchema = 
`id
MainInfo{
    Title{
        En
        Ar
    }
    Description{
        En
        Ar
    }
}
Price
ChatRoomConfig{
    ChairsCount
    AbilityToEditChatRoomStyle
    AbilityToAddModerators
}
Discount{
    isDiscount
    DiscountType
    Amount
}
Enabled
Priority
CreatedAt
`

const ChatRoomTypes = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [chatRoomsTypes,setChatRoomTypes] = useState([])
    const [selectedItem,setSelectedItem] = useState(null)

    const HandleMainInfoSubmit = (entitle,artitle,endesc,ardesc,price,ChairsCount,AbilityToEditChatRoomStyle,AbilityToAddModerators,isDiscount,DiscountType,Amount,Priority) =>{
        isNewItem ? AddNewChatRoomTypesFunc(entitle,artitle,endesc,ardesc,price,ChairsCount,AbilityToEditChatRoomStyle,AbilityToAddModerators,isDiscount,DiscountType,Amount,Priority) : EditChatRoomTypesFunc(entitle,artitle,endesc,ardesc,price,ChairsCount,AbilityToEditChatRoomStyle,AbilityToAddModerators,isDiscount,DiscountType,Amount,Priority)
    }

    const handleChangeDiscountSubmit = (isdiscount,discounttype,discountamount) =>{
        changeDiscountForAll(isdiscount,discounttype,discountamount)
    }
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewChatRoomTypes NewItem={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewChatRoomTypes NewItem={false} Item={selectedItem} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "DSC" : <ManageDiscount HandleMainInfoSubmit={handleChangeDiscountSubmit} closingModal={handleCloseModal} />,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewItem(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleManageDiscountBtnClick = () =>{
        setModalOpen(true)
        setModalStatus('DSC')

    }

    const handleEditClick = (interest) =>{
        setSelectedItem(interest)
        setModalStatus("EDIT")
        setIsNewItem(false)
        setModalOpen(true)
    }
    
    const showAccountHoldingThis = (item) =>{
        setSelectedItem(item)
        setModalStatus("ACC")
        setModalOpen(true)
    }
  

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteChatRoomType(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetChatRoomTypes{
                        ${ChatRoomTypesGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setChatRoomTypes(res.data.data.GetChatRoomTypes)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }
    const handleChangeEnable = async(state,item) =>{ 
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                ToggleEnableChatRoomType(id:"${item.id}",enable:${state}) {
                  ${ChatRoomTypesGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.ToggleEnableChatRoomType.id !== null){
            fetchData()
          }
    }

    const changeDiscountForAll = async(isdiscount,discounttype,discountamount) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                ManageDiscountToAllChatRoomsTypes(isdiscount:${isdiscount},discounttype:"${discounttype}",discountamount:${discountamount})
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
        if(res.data.data.ManageDiscountToAllChatRoomsTypes){
            fetchData()
        }
    }

    const AddNewChatRoomTypesFunc = async(entitle,artitle,endesc,ardesc,price,ChairsCount,AbilityToEditChatRoomStyle,AbilityToAddModerators,isDiscount,DiscountType,Amount,Priority) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewChatRoomType(entitle:"${entitle}",artitle:"${artitle}",endesc:"${endesc}",ardesc:"${ardesc}",price:${price},chairs:${ChairsCount},editstyle:${AbilityToEditChatRoomStyle},addmoder:${AbilityToAddModerators},isdiscount:${isDiscount},discounttype:"${DiscountType}",discountamount:${Amount},priority:${Priority}) {
                  ${ChatRoomTypesGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewChatRoomTypes.id !== null){
            fetchData()
          }
    }

    const EditChatRoomTypesFunc = async(entitle,artitle,endesc,ardesc,price,ChairsCount,AbilityToEditChatRoomStyle,AbilityToAddModerators,isDiscount,DiscountType,Amount,Priority) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditChatRoomType(id:"${selectedItem.id}",entitle:"${entitle}",artitle:"${artitle}",endesc:"${endesc}",ardesc:"${ardesc}",price:${price},chairs:${ChairsCount},editstyle:${AbilityToEditChatRoomStyle},addmoder:${AbilityToAddModerators},isdiscount:${isDiscount},discounttype:"${DiscountType}",discountamount:${Amount},priority:${Priority}){
                        ${ChatRoomTypesGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditChatRoomType.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Chat Room Types`} subtitle={`Add, modify, delete chat rooms types and manage discounts`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                <Box sx={{
                    display:"flex",
                    gap:1
                }}>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Type</Button>
                <Button variant="contained" onClick={handleManageDiscountBtnClick} color="secondary">Manage Discounts</Button>


                </Box>

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Item Name (ar)</TableCell>
        <TableCell>Item Name (en)</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Enabled</TableCell>
        <TableCell>Discount</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    chatRoomsTypes.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.MainInfo.Title.Ar}</TableCell>
        <TableCell>{itm.MainInfo.Title.En}</TableCell>
        <TableCell>{itm.CreatedAt}</TableCell>
        <TableCell><Switch checked={itm.Enabled} color="secondary" onChange={(e)=>{handleChangeEnable(e.target.checked,itm)}}/></TableCell>
        <TableCell>
            <Box sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                alignContent:"center",
                gap:1
            }}>
            {itm.Discount.isDiscount ? <DoneIcon color="secondary" /> : <CloseIcon color="error" />} {itm.Discount.isDiscount && itm.Discount.Amount}{(itm.Discount.isDiscount && itm.Discount.DiscountType === "PERCENTAGE") && "%"}
            </Box>
            </TableCell>

        <TableCell>
           
            <IconButton color="secondary" onClick={() => handleEditClick(itm)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(itm)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={chatRoomsTypes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default ChatRoomTypes