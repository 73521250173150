import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";


import AddNewContentLanauge from "./AddNewContentLanauge"


const API_URL = `https://dashapi.ingochat.com/`


const ContentLanguageGQLSchema = `
id
CountryName{
    ar
    en
}
CountryImage{
    FilePath
}
`

const ContentLanguages = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewLanguage,setIsNewLanguage] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [contentLanguages,setContentLanguages] = useState([])
    const [selectedLanguage,setSelectedLanguage] = useState(null)

    const HandleMainInfoSubmit = (ar,en,filepath) =>{
        isNewLanguage ? AddNewContentLanguage(ar,en,filepath) : EditContentLanguage(ar,en,filepath)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewContentLanauge NewLanguage={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewContentLanauge NewLanguage={false} Language={selectedLanguage} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewLanguage(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleEditClick = (language) =>{
        setSelectedLanguage(language)
        setModalStatus("EDIT")
        setIsNewLanguage(false)
        setModalOpen(true)
    }
    
  

    const handleDeleteClick = async(article) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteContentLanguage(id:"${article.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
                GetContentLanguages{
                ${ContentLanguageGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setContentLanguages(res.data.data.GetContentLanguages)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }

    const AddNewContentLanguage = async(ar,en,filepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewContentLanguage(ar:"${ar}",en:"${en}",filename:"${filepath}") {
                  ${ContentLanguageGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewContentLanguage.id !== null){
            fetchData()
          }
    }

    const EditContentLanguage = async(ar,en,filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditContentLanguage(id:"${selectedLanguage.id}",ar:"${ar}",en:"${en}",filename:"${filepath}"){
                        ${ContentLanguageGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditContentLanguage.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, []);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Content Lanauges`} subtitle={`Add, modify and delete content languages`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Lanuguage</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Language Name (ar)</TableCell>
        <TableCell>Language Name (en)</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    contentLanguages.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.CountryName.ar}</TableCell>
        <TableCell>{lng.CountryName.en}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(lng)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={contentLanguages.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
        </Box>
    )
}

export default ContentLanguages