import React, { useEffect, useState } from "react";
import { Box,IconButton, Button, TextField, Typography, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Switch } from "@mui/material";
import { Formik } from "formik";
import { tokens } from "./../../../theme";
import { useTheme } from "@emotion/react";
import CloseIcon from '@mui/icons-material/Close';
import { getAuthentication } from "../../../auth";
import axios from "axios";
const API_URL = `https://dashapi.ingochat.com/`
const UPLOAD_URL = `https://upload.ingochat.com/`

const CountriesGQLSchema = `
id
CountryName{
    ar
    en
}
CountryImage{
    FilePath
}
Enable
`

const HopesItemsGQLSchema = 
`id
Item{
    ar
    en
}`

const AddNewFakeAccountComp = ({  HandleMainInfoSubmit, closingModal }) => {
   const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("")
  const [fullname, setFullname] = useState("")
  const [gender, setGender] = useState("")
  const [maritalStatus, setMaritalStatus] = useState("")
  const [birthdate, setBirthdate] = useState("")
  const [originCountryId, setOriginCountryId] = useState("")
  const [originCityId, setOriginCityId] = useState("")
  const [isStudying, setIsStudying] = useState(false)
  const [studyName, setStudyName] = useState("")
  const [isWorking, setIsWorking] = useState(false)
  const [workName, setWorkName] = useState("")
  const [hope, setHope] = useState("")
  const [filepath,setFilePath] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')

  const [countries,setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [hopes,setHopesItems] = useState([])

  const handleSwitchChange = (field, value) => {
    switch (field) {
      case "isStudying":
        setIsStudying(value);
        break;
      case "isWorking":
        setIsWorking(value);
        break;
      default:
        break;
    }
  }

  const  generateFakeEmail = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const usernameLength = 16
    let username = ''
  
    for (let i = 0; i < usernameLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      username += characters.charAt(randomIndex);
    }
    const email = username + '@gmail.com';  
    setEmail(email)
  }


  const getHopesFromIngo = async () => {
    try{
    const res = await axios.post(API_URL,{
            query:`query{
                GetHopesFromIngo{
                    ${HopesItemsGQLSchema}
                }
            }`
        },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
        })
    if(res.status === 200){
      setHopesItems(res.data.data.GetHopesFromIngo)
      
    }
  }
  catch(err){

  }
}


  const getCountriesList = async() => {
    
  
    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
          GetCountires{
            ${CountriesGQLSchema}
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCountries(res.data.data.GetCountires)
    }
  }
  catch(err){

  }
  }

  const getCities = async () => {


    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
            getCitiesOfCountry(id:"${originCountryId}"){
                id
                CityName{
                    ar
                    en
                }
                Enable
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCities(res.data.data.getCitiesOfCountry)
    }
  }
  catch(err){

  }
}
  const handleSubmitBtn = () => {
    HandleMainInfoSubmit(
      email,
      fullname,
      gender,
      maritalStatus,
      birthdate,
      originCountryId,
      originCityId,
      isStudying,
      studyName,
      isWorking,
      workName,
      hope,
      filepath
    )
    closingModal()
  }

  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setFilePath(res.data.name)
    setMSG('File has uploaded successfully')
   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }

  useEffect(()=>{
    generateFakeEmail()
    getCountriesList()
    getHopesFromIngo()
  },[])

  useEffect(()=>{
    getCities()
  },[originCountryId])
  return (
    <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        height:"90%",
        bgcolor: colors.primary[400],
        overflowY:'scroll',
        boxShadow: 24,
        p: 4,
    }}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

      <Typography variant="h3" color="secondary" sx={{
        textAlign: "center"
      }}>Add New Fake Account</Typography>

   
            <TextField
              sx={{ mt: 3 }}
              variant="filled"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              fullWidth
              color="secondary"
            />
            <TextField
              sx={{ mt: 3 }}
              variant="filled"
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              label="Full Name"
              fullWidth
              color="secondary"
            />
            <FormControl sx={{ mt: 3, width: '100%' }}>
              <InputLabel color="secondary">Gender</InputLabel>
              <Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 3, width: '100%' }}>
              <InputLabel color="secondary">Marital Status</InputLabel>
              <Select
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
              >
                <MenuItem value="single">Single</MenuItem>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="other">Other</MenuItem>

              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 3 }}
              variant="filled"
              type="date"
              value={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
              label="Birth Date"
              fullWidth
              color="secondary"
            />
            <FormControl sx={{ mt: 3, width: '100%' }}>
              <InputLabel color="secondary">Origin Country</InputLabel>
              <Select
                value={originCountryId}
                onChange={(e) => setOriginCountryId(e.target.value)}
              >
                {
                  countries.map(cnt=>
                    (  <MenuItem key={cnt.id} value={cnt.id}>{cnt.CountryName.ar} - {cnt.CountryName.en}</MenuItem>)

                  )
                }
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 3, width: '100%' }}>
              <InputLabel color="secondary">Origin City</InputLabel>
              <Select
                value={originCityId}
                onChange={(e) => setOriginCityId(e.target.value)}
              >
              {cities && cities.length > 0 ? (
    cities.map((cnt) => (
      <MenuItem key={cnt.id} value={cnt.id}>
        {cnt.CityName.ar} - {cnt.CityName.en}
      </MenuItem>
    ))
  ) : (
    <MenuItem disabled value="">No cities available</MenuItem>
  )}
              </Select>
            </FormControl>
            <Box sx={{
                display:"flex",
                justifyContent:'space-between'
            }}>
            <FormControlLabel
              sx={{ mt: 3 ,width:'25%'}}
              control={<Switch color="secondary" checked={isStudying} onChange={(e) => handleSwitchChange("isStudying", e.target.checked)} />}
              label="Is Studying"
            />
            {isStudying && (
              <TextField
                sx={{ mt: 3,width:'70%' }}
                variant="filled"
                type="text"
                value={studyName}
                onChange={(e) => setStudyName(e.target.value)}
                label="Study Name"
                color="secondary"
              />
            )}
            </Box>
            <Box sx={{
                display:"flex",
                justifyContent:'space-between'
            }}>   
            <FormControlLabel
              sx={{ mt: 3 ,width:'25%'}}
              control={<Switch checked={isWorking} color="secondary" onChange={(e) => handleSwitchChange("isWorking", e.target.checked)} />}
              label="Is Working"
            />
            {isWorking && (
              <TextField
              sx={{ mt: 3,width:'70%' }}
              variant="filled"
                type="text"
                value={workName}
                onChange={(e) => setWorkName(e.target.value)}
                label="Work Name"
                color="secondary"
              />
            )}
                
            </Box>
            
            <FormControl sx={{ mt: 3, width: '100%' }}>
              <InputLabel color="secondary">Hope From Ingo</InputLabel>
              <Select
                value={hope}
                onChange={(e) => setHope(e.target.value)}
              >
                {
                  hopes.map(hp=>(
                    <MenuItem key={hp.id} value={hp.id}>{hp.Item.ar} - {hp.Item.en}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>
        </Box>
        <Typography sx={{textAlign:'center',mt:1}} color="error">{msg}</Typography>
            <Button onClick={handleSubmitBtn} sx={{ mt: 2 }} variant="contained" color="secondary">Add New Fake Account</Button>
    </Box>
  );
};

export default AddNewFakeAccountComp;
