import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import ReportDetails from "./ReportDetails";



const API_URL = `https://dashapi.ingochat.com/`

const FeedbacksGQLSchema = `
id
Account{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
ChatRoom{
    ContentConfig{
        ChatRoomName
    }
}
ReportType{
    Text{
        En
        Ar
    }
}
ReportText
CreatedAt
`

const ChatRoomReport = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [chatRoomReports,setChatRoomReports] = useState([])
    const [chatRoomReportsCount,setChatRoomReportsCounts] = useState(0)
    const [selectedItem,setSelectedItem] = useState(null)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('DTL')

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents = {
        "DTL": <ReportDetails selectedItem={selectedItem}  closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }
  
    const showDetail = (item) =>{
        setSelectedItem(item)
        setModalStatus("DTL")
        setModalOpen(true)
    }

    
    const GetChatRoomReportsCount = async () =>{
        try{
          const res = await axios.post(API_URL,{
            query:`query{
              GetStaticNumber(key:"SendAChatRoomReport"){
                Value
              }
            }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setChatRoomReportsCounts(res.data.data.GetStaticNumber.Value)          
          }
        }
        catch(err){}
      }

      const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetChatRoomReports(limit:${rowsPerPage},offset:${page}){
                        ${FeedbacksGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setChatRoomReports(res.data.data.GetChatRoomReports)
          setLoaderVisibility('none')
          GetChatRoomReportsCount()
        }
      }
      catch(err){
  
      }
    }
    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
   
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Chat Room Reports`} subtitle={`Display Chat Room Reports`} />

            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"flex-end",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
            </Box>

            <Box
                m="10px 0 0 0"
                height="50vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Account</TableCell>
        <TableCell>Chat Room</TableCell>
        <TableCell>Report Type</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    chatRoomReports.map((itm,index) => (
        <TableRow key={`PaymentsLog${index}`}>
        <TableCell>{itm.Account.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{itm.ChatRoom.ContentConfig.ChatRoomName}</TableCell>
        <TableCell>{itm.ReportType.Text.Ar} - {itm.ReportType.Text.En}</TableCell>
        <TableCell>{itm.CreatedAt}</TableCell>
        <IconButton onClick={()=>showDetail(itm)}>
            <RemoveRedEyeOutlined />
        </IconButton>
        
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={chatRoomReportsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default ChatRoomReport