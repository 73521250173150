import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import FeedbacksTab from "./FeedbacksTab";
import FeedbackCategories from "./FeedbackCategory";


const API_URL = `https://dashapi.ingochat.com/`


const TabPanel = (props) => {
    const { children, value, index } = props;
  
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

const FeedbacksContainer = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [tabValues, setTabValues] = useState(0);


    const handleChange = (event, newValue) => {
        setTabValues(newValue);
    }
 

  




   
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Feedbacks`} subtitle={`Add, modify and delete feedback categories, check feedbacks`} />
            <Tabs   indicatorColor="secondary"  textColor="secondary" value={tabValues} onChange={handleChange} >
                <Tab label="Feedbacks" sx={{fontSize:'18px'}} />
                <Tab label="Feedback Categories" sx={{fontSize:'18px'}} />
            </Tabs>
            <TabPanel value={tabValues} index={0}>
              <FeedbacksTab />
            </TabPanel>
            <TabPanel value={tabValues} index={1}>
              <FeedbackCategories />
            </TabPanel>
            
        </Box>
    )
}

export default FeedbacksContainer