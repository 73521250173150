import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";
import AddNewFeedbackCategoryComp from "./AddNewFeedbackCategory";


const API_URL = `https://dashapi.ingochat.com/`


const FeedbackCategoryGQLSchema = 
`id
Title{
    En
    Ar
}
CreatedAt
`

const FeedbackCategories = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [feedbackCategories,setFeedbackCategories] = useState([])
    const [selectedItem,setSelectedItem] = useState(null)

    const HandleMainInfoSubmit = (ar,en) =>{
        isNewItem ? AddNewFeedbackCategories(ar,en) : EditFeedbackCategories(ar,en)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewFeedbackCategoryComp NewItem={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewFeedbackCategoryComp NewItem={false} Item={selectedItem} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewItem(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleEditClick = (interest) =>{
        setSelectedItem(interest)
        setModalStatus("EDIT")
        setIsNewItem(false)
        setModalOpen(true)
    }
    
  

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteFeedbackCategory(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetFeedBackCategory{
                        ${FeedbackCategoryGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setFeedbackCategories(res.data.data.GetFeedBackCategory)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }

    const AddNewFeedbackCategories = async(ar,en) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewFeedbackCategory(ar:"${ar}",en:"${en}") {
                  ${FeedbackCategoryGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewFeedbackCategory.id !== null){
            fetchData()
          }
    }

    const EditFeedbackCategories = async(ar,en) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditFeedbackCategory(id:"${selectedItem.id}",ar:"${ar}",en:"${en}"){
                        ${FeedbackCategoryGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditFeedbackCategory.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, []);
    return(
        <Box sx={{
            m:'5px'
        }}>
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Category</Button>

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Item Name (ar)</TableCell>
        <TableCell>Item Name (en)</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    feedbackCategories.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.Title.Ar}</TableCell>
        <TableCell>{itm.Title.En}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(itm)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(itm)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={feedbackCategories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default FeedbackCategories