import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import CircularProgress from '@mui/material/CircularProgress'

import { useEffect, useState } from "react"
import axios from "axios"
import { getAuthentication } from "../../../auth"
import { tokens } from "../../../theme"
import { CheckOutlined, CloseOutlined, RemoveRedEyeOutlined } from "@mui/icons-material"
import DisplayGuestAccount from "./DisplayGuestAccount"
import DisplayStandardAccount from "./DisplayStandardAccount"

const API_URL = `https://dashapi.ingochat.com/`

const ColorPalletsGQLSchema = 
`id
ColorHex
Price
Title{
    En
    Ar
}
Discount{
    isDiscount
    DiscountType
    Amount
}
Enabled
Priority
CreatedAt
`
const AwardHoldersGQLSchema = 
`id
Image{
    FilePath
}
Price
Title{
    En
    Ar
}
Discount{
    isDiscount
    DiscountType
    Amount
}
Enabled
Priority
CreatedAt
`
const AccountCoverSchemaGQL = `
id
CoverType
MainInfo {
    Name {
        ar
        en
    }
    Category {
    id
        Name {
            ar
            en
        }
    }
    CoverImage {
        FilePath
    }
    CoverColor {
        Hex
    }
}
Price
Infos {
    createdAt
}
`


const FavouriteItemSchemaGQL = `
id
MainInfo{
  Name{
    ar
    en
  }
  Description{
    ar
    en
  }
  FavouriteItemType{
    id
    MainInfo{
      Name{
        ar
        en
      }
      Type
      Description{
        ar
        en
      }
    }
    MainImage{
      FilePath
    }
    Infos{
      categoriesCount
      Count
      createdAt
    }
  }
  FavouriteItemCategory{
    id
    MainInfo{
      Name{
        ar
        en
      }
      Description{
        ar
        en
      }
      FavouriteItemType{
        id
        MainInfo{
          Name{
            ar
            en
          }
          Type
          Description{
            ar
            en
          }
        }
        MainImage{
          FilePath
        }
        Infos{
          categoriesCount
          Count
          createdAt
        }
      }
    }
    MainImage{
      FilePath
    }
    Infos{
      Count
      createdAt
    }
  }
}
MainImage{
  FilePath
}
Infos{
  createdAt
  CD_ID
  AddByUser
  AddByDash
}
`
const ContentSpecificationGQLSchema = `
ImageCountAllowed
ContentLanguages{
  CountryName{
    ar
    en
  }
  CountryImage{
    FilePath
  }
}
Interests{
  id
  InterestName{
    ar
    en
  }
}
FavouriteItems{
  ${FavouriteItemSchemaGQL}
}
AccountCover{
  OwnedCovers{
    ${AccountCoverSchemaGQL}
  }
}
AwardHolder{
  OwnedAwardHolder{
    ${AwardHoldersGQLSchema}
  }
}
ColorPallets{
  OwnedColorPallet{
    ${ColorPalletsGQLSchema}
  }
}
`
const AccountDataMainInfoGQLSchema = `
  FullName
  Gender
  MaritalStatus
  Birthdate
  Country{
    InAnotherCountryLive
    OriginCountry{
      Country{
        CountryName{
          ar
          en
        }
        CountryImage{
          FilePath
        }
      }
      City{
        CityName{
          ar
          en
        }
      }
    }
    OtherCountry{
      Country{
        CountryName{
          ar
          en
        }
        CountryImage{
          FilePath
        }
      }
      City{
        CityName{
          ar
          en
        }
      }
    }
  }
  Job{
    isWorking
    WorkName
  }
  Study{
    isStudying
    StudyName
  }
  Bio
  HopeFromIngo{
    Item{
      ar
      en
    }
  }
`
const AccountDataGQLSchema = `
id
MainInfo{
  ${AccountDataMainInfoGQLSchema}
}
MainImages{
  FilePath
  UploadDate
}
Gallary{
  FilePath
  UploadDate
}
ContentSpecification{
  ${ContentSpecificationGQLSchema}
}
PrivacySettings{
  AllowAddToChatRoomWithoutPermission
  AllowUserSendMessagesToMe
  UseNickName
  PrivacySettingsType
  SpecifiedSettings{
    Bio
    Birthdate
    City
    Country
    Image
    MaritalStatus
    Name
    Study
    WhyAmHere
    Work
  }
}
Credits
`
const AccountGQLSchema = `
id
AccountType
AccountStatus{
    Activated
    Banned
}
StandardAccount{
  VerificationStatus{
    Verified
  }
  Credentials{
    Email
  }
}
AccountData{
  ${AccountDataGQLSchema}
}
Friends{
    id
}
Blocked{
    id
}
LastLogin{
  Date
}
AppConfig{
    Notification
    DarkMode
    AppLanguage
}
Infos{
    CreatedAt
}
`
const Accounts = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);



    const [accounts,setAccounts] = useState([])
    const [selectedAccount,setSelectedAccount] = useState(null)
    const [accountCount,setAccountCount] = useState(0)

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('DSP')
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)


    

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleToggleBanState = (state) =>{
      ToggleAccountBannedState(state)
    }

    const ModalComponents = {
        "GSTDSP": <DisplayGuestAccount handleToggleBanState={handleToggleBanState} Account={selectedAccount} closingModal={handleCloseModal} />,
        "STDDSP": <DisplayStandardAccount handleToggleBanState={handleToggleBanState} Account={selectedAccount} closingModal={handleCloseModal} />
    }

    const DisplayGuestAccountFunc = (account) =>{
        setModalStatus("GSTDSP")
        setModalOpen(true)
        setSelectedAccount(account)
    }

    const DisplayStandardAccountFunc = (account) =>{
      setModalStatus("STDDSP")
      setModalOpen(true)
      setSelectedAccount(account)
  }

    const GetAccountsCounts = async () =>{
        try{
          const res = await axios.post(API_URL,{
            query:`query{
              GetStaticNumber(key:"AccountsCount"){
                Value
              }
            }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setAccountCount(res.data.data.GetStaticNumber.Value)          
          }
        }
        catch(err){}
    }

    const ToggleAccountBannedState = async(state) =>{
      console.log(state)
      try {
        await axios.post(API_URL, {
         query: `
           mutation {
            ToggleAccountBannedState(id:"${selectedAccount.id}",banned:${state}) 
           }
         `
       }, {
         headers: {
           token: `Bearer ${getAuthentication()}`
         }
       });
       fetchData()
     } catch (error) {}

    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetAccounts(limit:${rowsPerPage},offset:${page}){
                        ${AccountGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setAccounts(res.data.data.GetAccounts)
          setLoaderVisibility('none')
          GetAccountsCounts()
        }
      }
      catch(err){}
    }


    useEffect(() => {
        fetchData()
      }, [rowsPerPage,page])

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Accounts`} subtitle={`Managing Accounts`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"flex-end"
            }}>
                  
                  

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>
            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Full Name</TableCell>
        <TableCell>Account Type / Verified</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    accounts.map(acc => (
        <TableRow key={acc.id}>
            <TableCell>{acc.AccountType === 'guest' ? 'Guest' : acc.AccountData.MainInfo.FullName !== '' ? acc.AccountData.MainInfo.FullName : <span style={{color:"red"}}>Not set yet</span>}</TableCell>
            <TableCell sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:1}}>{acc.AccountType} {acc.AccountType === 'standard' && acc.StandardAccount.VerificationStatus.Verified ? <CheckOutlined  color="secondary"/>  : <CloseOutlined color="error"/> }</TableCell>
            <TableCell>{new Date(acc.Infos.CreatedAt).toLocaleString()}</TableCell>
            <TableCell>
                {
                    acc.AccountType === 'guest' && <IconButton color="warning" onClick={()=>DisplayGuestAccountFunc(acc)}><RemoveRedEyeOutlined /></IconButton>
                }
                {
                  acc.AccountType !== 'guest' && acc.StandardAccount.VerificationStatus.Verified && <IconButton color="warning" onClick={()=>DisplayStandardAccountFunc(acc)}><RemoveRedEyeOutlined /></IconButton>
                }
            </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10]}
        component="div"
        count={accountCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>

        </Box>
    )
}

export default Accounts