import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { Label } from "@mui/icons-material";

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const  formatDate = (datestamp) => {
    const date = new Date(datestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}
const AddNewAd = ({NewAd,Ad,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const [enTitle,setEnTitle] = useState(NewAd?'':Ad.MainInfo.Title.En)
  const [arTitle,setArTitle] = useState(NewAd?'':Ad.MainInfo.Title.Ar)
  const [enDescription,setEnDescription] = useState(NewAd?'':Ad.MainInfo.Description.En)
  const [arDescription,setArDescription] = useState(NewAd?'':Ad.MainInfo.Description.Ar)
  const [type,setType] = useState(NewAd?'':Ad.MainInfo.Type)
  const [startDate,setStartDate] = useState(NewAd?"":formatDate(Ad.Duration.StartDate))
  const [endDate,setEndDate] = useState(NewAd?"":formatDate(Ad.Duration.EndDate))
  const [btn1TextEn,setBtn1TextEn] = useState(NewAd?"":Ad.Buttons.Button1.Text.En)
  const [btn1TextAr,setBtn1TextAr] = useState(NewAd?"":Ad.Buttons.Button1.Text.Ar)
  const [btn1Link,setBtn1Link] = useState(NewAd?"":Ad.Buttons.Button1.Link)
  const [btn2TextEn,setBtn2TextEn] = useState(NewAd?"":Ad.Buttons.Button2.Text.En)
  const [btn2TextAr,setBtn2TextAr] = useState(NewAd?"":Ad.Buttons.Button2.Text.Ar)
  const [btn2Link,setBtn2Link] = useState(NewAd?"":Ad.Buttons.Button2.Link)
  const [filepath,setFilePath] = useState(NewAd?'':Ad.MainImage.FilePath)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')


  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(enTitle,arTitle,enDescription,arDescription,type,startDate,endDate,btn1TextEn,btn1TextAr,btn1Link,btn2TextEn,btn2TextAr,btn2Link,filepath)
    closingModal()

  }

  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setFilePath(res.data.name)
    setMSG('File has uploaded successfully')
   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewAd ? "Add New Ad" : "Edit Ad"}</Typography>
      {!NewAd ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Ad.MainInfo.Title.Ar + ' - ' + Ad.MainInfo.Title.En}</Typography>):(<></>) }
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={enTitle}
                    onChange={(e)=>setEnTitle(e.target.value)}
                    label="Ad Title (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={arTitle}
                    onChange={(e)=>setArTitle(e.target.value)}
                    label="Ad Title (ar) ..."
                    color="secondary"
                    />
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={enDescription}
                    onChange={(e)=>setEnDescription(e.target.value)}
                    label="Ad Description (en) ..."
                    color="secondary"
                    multiline
                    rows={3} 
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={arDescription}
                    onChange={(e)=>setArDescription(e.target.value)}
                    label="Ad Description (ar) ..."
                    color="secondary"
                    multiline
                    rows={3} 
                    />
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'100%'
                    }}
                    variant="filled"
                    type="text"
                    value={type}
                    onChange={(e)=>setType(e.target.value)}
                    label="Type"
                    color="secondary"
                    />
                   
        </Box>
        <Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>
        </Box>
        <Typography sx={{textAlign:'center',mt:1}} color="error">{msg}</Typography>
       
        <Box sx={{
            mt:2,
            display:'flex',
            justifyContent:'space-around'
        }}>
                <Box sx={{width:"48%"}}>
                    <Typography sx={{px:1}}>Start Date</Typography>
                    <TextField 
                    sx={{
                        mt:1,
                        width:'100%'
                    }}
                    type="date"
                    value={startDate}
                    onChange={(e)=>setStartDate(e.target.value)}
                    color="secondary"
                    />
                </Box>
                
                <Box sx={{width:"48%"}}>
                    <Typography sx={{px:1}}>End Date</Typography>
                    <TextField 
                    sx={{
                        mt:1,
                        width:'100%'
                    }}
                    type="date"
                    value={endDate}
                    onChange={(e)=>setEndDate(e.target.value)}
                    color="secondary"
                    />
                </Box>
        </Box>

        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn1TextEn}
                    onChange={(e)=>setBtn1TextEn(e.target.value)}
                    label="Button 1 Text (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn1TextAr}
                    onChange={(e)=>setBtn1TextAr(e.target.value)}
                    label="Button 1 Text (ar) ..."
                    color="secondary"
                    />
                       <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn1Link}
                    onChange={(e)=>setBtn1Link(e.target.value)}
                    label="Button 1 Link ..."
                    color="secondary"
                    />
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn2TextEn}
                    onChange={(e)=>setBtn2TextEn(e.target.value)}
                    label="Button 2 Text (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn2TextAr}
                    onChange={(e)=>setBtn2TextAr(e.target.value)}
                    label="Button 2 Text (ar) ..."
                    color="secondary"
                    />
                       <TextField 
                    sx={{
                        mt:3,
                        width:'31%'
                    }}
                    variant="filled"
                    type="text"
                    value={btn2Link}
                    onChange={(e)=>setBtn2Link(e.target.value)}
                    label="Button 2 Link ..."
                    color="secondary"
                    />
        </Box>
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewAd? `Add Ad` : `Edit Ad`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewAd;