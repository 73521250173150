import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from "axios"

import { tokens } from "../../../theme"

import { getAuthentication } from "../../../auth"
import AddNewStandardPost from "./AddNewStandardPost"
import AddNewPoll from "./AddNewPoll" 
import { RemoveRedEyeOutlined } from "@mui/icons-material"
import DisplayPost from "./DisplayPost"


const API_URL = `https://dashapi.ingochat.com/`


const PostGQLSchema = 
`
id
PostType
MainInfo {
    Standard {
        Enable
        Text
        Languages {
            lang
            prob
        }
        isEmbed
        EmbedObj {
            Type
            Link
        }
        Images
    }
    Poll {
        Enable
        PollType
        Question
        Options {
            Text
            Image
            Count
            From
        }
        TotalCount
        TotalAccounts {
            id
        }
        Languages {
            lang
            prob
        }
    }
    Interests{
        id
        InterestName {
            ar
            en
        }
    }
}
Likes {
    Count
    Likes{
      id
    }
}
Comments {
    Count
    Comments{id}
}
Shares {
    Count
    Shares{
      id
    }
}
Infos {
    isPublic
    IngoAccount
    CreatedBy {
        id
        AccountData{
          MainInfo{
            FullName
          }
          MainImages{
            FilePath
            UploadDate
          }
        }
    }
    createdAt
}
`

const Posts = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewPost,setIsNewPost] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [posts,setPosts] = useState([])
    const [selectedPost,setSelectedPost] = useState(null)
    const [PostsCount,setPostsCount] = useState(0)

    const HandleMainInfoSubmit = (text,isEmbed,embedType,embedLink,imgs,interests) =>{
        isNewPost ? AddNewStandardPostFunc(text,isEmbed,embedType,embedLink,imgs,interests) : EditStandardPostFunc(text,isEmbed,embedType,embedLink,imgs,interests)
    }

    const HandlePollMainInfoSubmit = (pollType,question,options,imgs,interests) => {
      AddNewPollPostFunc(pollType,question,options,imgs,interests)   
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const ModalComponents = {
        "ADD": <AddNewStandardPost NewPost={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewStandardPost NewPost={false} Post={selectedPost} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "ADDPOLL":<AddNewPoll NewPoll={true} HandleMainInfoSubmit={HandlePollMainInfoSubmit} closingModal={handleCloseModal} />,
        "DSPSTD":<DisplayPost Post={selectedPost} closingModal={handleCloseModal} />
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewPost(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleNewPollBtnClick = () =>{
      setIsNewPost(true)
      setModalOpen(true)
      setModalStatus('ADDPOLL')
    }

    const handleEditClick = (interest) =>{
        setSelectedPost(interest)
        setModalStatus("EDIT")
        setIsNewPost(false)
        setModalOpen(true)
    }
    
    const DisplayStandardPost = (post) =>{
      setSelectedPost(post)
      setModalStatus("DSPSTD")
      setModalOpen(true)
    }

    const DisplayPollPost = (poll) =>{
      setSelectedPost(poll)
      setModalStatus("DSPLL")
      setModalOpen(true)

    }
  

    const handleDeleteClick = async(interest) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeletePost(id:"${interest.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
          fetchData()
        } catch (error) {}
    }

    const GetPostsCounts = async () =>{
      try{
        const res = await axios.post(API_URL,{
          query:`query{
            GetStaticNumber(key:"PostCount"){
              Value
            }
          }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setPostsCount(res.data.data.GetStaticNumber.Value)          
        }
      }
      catch(err){}
    }

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetPosts(limit:${rowsPerPage},offset:${page}){
                        ${PostGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setPosts(res.data.data.GetPosts)
          setLoaderVisibility('none')
          GetPostsCounts()
        }
      }
      catch(err){}
    }

    const AddNewStandardPostFunc = async(text,isEmbed,embedType,embedLink,imgs,interests) => {
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewStandardPost(text:"${text}",isembed:${isEmbed},embedtype:"${embedType}",embedlink:"${embedLink}",imgs:"${imgs}",interests:"${interests}") {
                  ${PostGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewStandardPost.id !== null){
            fetchData()
          }
    }


    const AddNewPollPostFunc = async(pollType,question,options,imgs,interests) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            AddNewPollPost(polltype:"${pollType}",question:"${question}",options:"${options}",imgs:"${imgs}",interests:"${interests}") {
              ${PostGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.AddNewPollPost.id !== null){
        fetchData()
      }
    }

    

    const EditStandardPostFunc = async(text,isEmbed,embedType,embedLink,imgs,interests) => {
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditStandardPost(id:"${selectedPost.id}",text:"${text}",isembed:${isEmbed},embedtype:"${embedType}",embedlink:"${embedLink}",imgs:"${imgs}",interests:"${interests}"){
                        ${PostGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditStandardPost.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
      fetchData()
    }, [rowsPerPage,page])

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Posts`} subtitle={`Add, modify, manage and delete posts (Under Maintenance)`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                   <Box>
                   <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Post</Button>
                   <Button sx={{mx:2}} variant="contained" onClick={handleNewPollBtnClick} color="secondary">Add New Poll</Button>

                   </Box>
                  

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Publisher</TableCell>
        <TableCell>Post Type</TableCell>
        <TableCell>Post Date</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    posts.map(pst => (
        <TableRow key={pst.id}>
        <TableCell>{pst.Infos.IngoAccount ? "INGO Dashboard": pst.Infos.CreatedBy.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{pst.PostType.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</TableCell>
        <TableCell>{pst.Infos.createdAt}</TableCell>
        <TableCell>

          <IconButton color="warning" onClick={()=>{
            pst.PostType === 'standard' ? DisplayStandardPost(pst) : DisplayPollPost(pst)
          }}>
            <RemoveRedEyeOutlined />
          </IconButton>

          {
            (pst.PostType === 'standard' && pst.Infos.IngoAccount) && (
              <IconButton color="secondary" onClick={() => handleEditClick(pst)}> 
                <EditIcon />
            </IconButton>
            )
          }
            
            <IconButton color="error" onClick={() => handleDeleteClick(pst)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={PostsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default Posts