import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch,Chip } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const AccountGQLSchema = `
id
AccountData{
    MainInfo{
        FullName
    }
}
`
const AddNewColorPallet = ({NewItem,Item,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [tags, setTags] = useState(NewItem ? [] : Item.ColorHex);
  const [inputValue, setInputValue] = useState('');

  const [accounts,setAccounts] = useState([])
  const [account,setAccount] = useState('')
  const [ar,setAr] = useState('')
  const [en,setEn] = useState('')

  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(account,en,ar)
    closingModal()

  }

  const fetchData = async() =>{
    try{
        const res = await axios.post(API_URL,{
            query:`query{
                GetWholeAccounts{
                    ${AccountGQLSchema}
                }
            }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setAccounts(res.data.data.GetWholeAccounts)
        }
    }
    catch(err){

    }
  }
 

  
  useEffect(()=>{
    fetchData()
  },[])


  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"70%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewItem ? "Add New Warning" : "Edit Color Pallet"}</Typography>
     
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            width:"100%",
            mt:2

        }}>
              <InputLabel color="secondary" id="demo-simple-select-label">Select Account ...</InputLabel>

            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{
                    width:"100%",
                }}
                color="secondary"
                value={account}
                variant="filled"
                label="Select Account ..."
                onChange={(e)=>setAccount(e.target.value)}
            >
                {
                    accounts.map((acc)=>(
                        <MenuItem value={acc.id}>{acc.AccountData.MainInfo.FullName}</MenuItem>
                    ))
                }
            </Select>
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Item Title (en) ..."
                    color="secondary"
                    rows={5}
                    multiline
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Item Title (ar) ..."
                    color="secondary"
                    rows={5}
                    multiline
                    />
        </Box>
        
       
     
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewItem? `Add Warning` : `Edit Color Pallet`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewColorPallet;