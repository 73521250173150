import React, { useEffect, useState } from 'react'
import { Box,Dialog,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,DialogActions,DialogContent,DialogTitle, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { CheckOutlined, Close, CloseOutlined } from '@mui/icons-material';
import { RemoveRedEyeOutlined } from "@mui/icons-material";

import axios from 'axios';
import { getAuthentication } from '../../../auth';
import { tokens } from '../../../theme';

const API_URL = `https://dashapi.ingochat.com/`

const DashboardWarningGQLSchema = `
id
Account{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
Text{
    En
    Ar
}
CreatedAt
`
const AccountWarnings = ({Account}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [warnings,setWarnings] = useState([])

    const [selectedItem,setSelectedItem] = useState(null)
    const [ar,setAr] = useState('')
    const [en,setEn] = useState('')
   
    const handleClosePreview = () => {
        setSelectedItem(null);
      }

    const HandleSubmitBtn = () =>{
        AddNewWarning()
    }

    const AddNewWarning = async() =>{
        try{
            const res = await axios.post(API_URL,{
                query: `mutation{
                    SendNewWarning(id:"${Account.id}",en:${JSON.stringify(en)},ar:${JSON.stringify(ar)}){
                        ${DashboardWarningGQLSchema}
                    }
                }`
            },{
                headers:{
                    token:`Bearer ${getAuthentication()}`
                }
            })

            if(res.data.data.SendNewWarning.id){
                fetchData()
            }
        }
        catch(err){

        }
    }
    const fetchData = async () => {
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetWarningsOfAccount(id:"${Account.id}"){
                        ${DashboardWarningGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setWarnings(res.data.data.GetWarningsOfAccount)
        }
      }
      catch(err){
  
      }
    }

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <Box>
              <Dialog open={!!selectedItem} onClose={handleClosePreview} maxWidth="md">
    <DialogActions>
        <IconButton onClick={handleClosePreview}><Close /></IconButton>
    </DialogActions>
    {selectedItem && (
        <>
            <DialogTitle><b>Uploaded Date:</b> {new Date(selectedItem.CreatedAt).toLocaleString()}</DialogTitle>
            <DialogContent>
                <Box sx={{
                    display:"flex",
                    flexDirection:"row",
                    mt:2
                }}>
                    <Typography sx={{width:"49%"}}>
                        {selectedItem.Text.En}
                    </Typography>
                    <Typography sx={{width:"49%",textAlign:"right"}}>
                        {selectedItem.Text.Ar}
                    </Typography>
                </Box>
            </DialogContent>
        </>
    )}
</Dialog>

            <Box sx={{
            display:'flex',
            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Item Title (en) ..."
                    color="secondary"
                    rows={3}
                    multiline
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Item Title (ar) ..."
                    color="secondary"
                    rows={3}
                    multiline
                    />
            </Box>
            <Box sx={{
                    px:'5px'
                }}>
                <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add Warning</Button>

                </Box>
                    <Box
                        m="30px 0 0 0"
                        height="50vh"
                        sx={{
                        "& .MuiDataGrid-root": {
                        border: "none",
                        },
                        "& .MuiTable-root":{
                            borderRadius:"15px"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                        color: colors.greenAccent[300],
                            },
                        "& .MuiTableHead-root": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                            },
                        "& .MuiTableBody-root": {
                        backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiTableCell-root":{
                            borderBottom:'none',
                            textAlign:"center"
                        },
                        
                        
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                        color:`${colors.grey[100]} !important`
                        }
                    }}
                    >
        <TableContainer  >
        <Table>
            <TableHead>
            <TableRow >
                <TableCell>Account</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            </TableHead>
            
            <TableBody>
            {
            warnings.map((itm,index) => (
                <TableRow key={`PaymentsLog${index}`}>
                <TableCell>{itm.Account.AccountData.MainInfo.FullName}</TableCell>
                <TableCell>{itm.CreatedAt}</TableCell>
                <TableCell>
                <IconButton >
                    <RemoveRedEyeOutlined onClick={()=>setSelectedItem(itm)} />
                </IconButton>
                </TableCell>
            
                
            </TableRow>))}
            </TableBody>
        </Table>
        </TableContainer>   
                
                </Box>
        </Box>
        
    )
}

export default AccountWarnings