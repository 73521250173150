import { Box,useTheme,CircularProgress,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Checkbox,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useEffect, useState } from "react"
import axios from "axios"
import { getAuthentication } from "../../../auth"
const API_URL = `https://dashapi.ingochat.com/`

const IngoDashboard = () => {
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [staticDatas,setStaticData] = useState([])

    const fetchData = async() =>{
        setLoaderVisibility('flex')

        const res = await axios.post(API_URL,{
            query:`query{
                GetAllStaticNumber{
                    Key
                    Value
                }
            }`
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          })

        if(res.data.data.GetAllStaticNumber){
            const arr = res.data.data.GetAllStaticNumber.slice().sort((a, b) => a.Key.localeCompare(b.Key))
            setStaticData(arr)
            setLoaderVisibility('none')

        }
    }

    useEffect(()=>{
        fetchData()  
    },[])

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Dashboard`} subtitle={`Review all the statistics available in the IngoChat application`} />
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>
            <Box sx={{
                display:'flex',
                flexWrap:'wrap',
                
            }}>
                {staticDatas.map(st=>(
                   <Box sx={{width:'15%',m:1,p:2}}>
                        <Typography  variant="h3">{st.Value}</Typography>
                        <Typography color="secondary" variant="h6">{st.Key.split(/(?=[A-Z])/).join(' ').replace(' Count', '')}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default IngoDashboard