import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const ManageDiscount = ({HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


 
  const [discountType,setDiscountType] = useState( "PERCENTAGE")
  const [amount,setAmount] = useState("")
  const [isDiscount,setIsDiscount] = useState(false)
 

  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(isDiscount,discountType,amount === '' ? 0 : amount)
    closingModal()

  }





  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Modify Discount Settings For All Color Pallet</Typography>
      <Typography variant="h6" color={`error`} sx={{
        textAlign:"center"
      }}>The application of these discount settings will uniformly impact all color pallets, leading to the removal and irretrievable loss of any pre-existing discount configurations associated with each item.</Typography>
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        
        
      
        <Box sx={{
            mt:3,
            display:"flex",
            alignItems:"center"
        }}> 
            <Switch checked={isDiscount} color="secondary" onChange={()=>{setIsDiscount(!isDiscount)}}/>
            <Typography>is there a discount</Typography>
        </Box>
        {
            isDiscount && 
            <Box sx={{
                display:'flex',
                justifyContent:'space-around'
            }}>
                <Select sx={{
                        mt:3,
                        width:'48%'
                    }}
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value="PERCENTAGE">Percentage (The discount applied will be calculated as a percentage reduction from the original price.)</MenuItem>
                <MenuItem value="AMOUNT">Amount (The pricing mechanism involves a fixed price, devoid of any percentage adjustments, resulting in a definite new price.)</MenuItem>
              </Select>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={amount}
                    onChange={(e)=>setAmount(e.target.value)}
                    label="Discount Amount"
                    color="secondary"
                    />
            </Box>
        }
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Apply Discount Settings</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default ManageDiscount;