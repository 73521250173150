import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";


const API_URL = `https://dashapi.ingochat.com/`


const FriendshipsGqlSchema = 
`
id
From{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
To{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
Confirmed
ConfirmeDate
RequestDate
`

const Friendships = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [friendships,setFriendShips] = useState([])
    const [friendshipsCount,setFriendShipsCount] = useState(0)
 

   
  

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    
    const GetFriendShipsCount = async () =>{
        try{
          const res = await axios.post(API_URL,{
            query:`query{
              GetStaticNumber(key:"Friendships"){
                Value
              }
            }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setFriendShipsCount(res.data.data.GetStaticNumber.Value)          
          }
        }
        catch(err){}
      }

  


    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetAllFriendShips(limit:${rowsPerPage},offset:${page}){
                        ${FriendshipsGqlSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setFriendShips(res.data.data.GetAllFriendShips)
          setLoaderVisibility('none')
          GetFriendShipsCount()
        }
      }
      catch(err){
  
      }
    }

   

   

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
          

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="50vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Request From</TableCell>
        <TableCell>Request To</TableCell>
        <TableCell>Confirmed</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    friendships.map(itm => (
        <TableRow key={itm.id}>
        <TableCell>{itm.From.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{itm.To.AccountData.MainInfo.FullName}</TableCell>
        <TableCell>{itm.Confirmed ? <CheckIcon color="secondary" /> : <CloseIcon color="error" /> }</TableCell>
       
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={friendshipsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />         
        </Box>
        </Box>
    )
}

export default Friendships