import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import JoditEditor from 'jodit-react';


const EditStaticText = ({statictext,HandleMainInfoSubmit,lngtitle}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ar,setAr] = useState(statictext.Value.ar)
  const [en,setEn] = useState(statictext.Value.en)
  const [languageShow,setLanguageShow] = useState('ar')
  const [title,setTitle] = useState(lngtitle)
  const [isEnable,setIsEnable] = useState(statictext.Enable)
  const HandleSubmitBtn = () =>{ 
    HandleMainInfoSubmit(ar,en,isEnable)
  }

 const handleChangeSelect = (value) =>{
    setLanguageShow(value)
 }



 const copyStringToClipboard = (str)  =>{
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}
const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          console.log(mergeField);
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      console.log(divElement);
      return divElement;
    }
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function(editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  }
];

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true
  },
  style : {
      color: '#202020',
    },
  width: "100%",
  height: "45vh"

}



  useEffect(() => {
      
  }, [])


  return (
    <Box >

      <Typography variant="h3" sx={{textAlign:"center"}}>

        {title}
      </Typography>

        

        <Box sx={{my:2}}>
            <InputLabel color="secondary" sx={{mb:1}} id="demo-simple-select-label">Select Language </InputLabel>
            <Select variant="filled" sx={{width:'30%'}}  value={languageShow} onChange={(e)=>handleChangeSelect(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Type ..." >
                <MenuItem key="key_static_text_ar" value="ar" >Arabic</MenuItem>
                <MenuItem key="key_static_text_en" value="en" >English</MenuItem>
            </Select>
        </Box>
        <Box>
            <InputLabel color="secondary"  id="demo-simple-select-label">Enable {title}</InputLabel>
            <Switch checked={isEnable} color="secondary" onChange={(e)=>{setIsEnable(e.target.checked)}}/>
        </Box>
        <Box sx={{display:languageShow === 'ar' ? 'block':'none'}}>

        <JoditEditor 
                    ref={editorConfig}
                    value={ar}
                    config={editorConfig}
                    tabIndex={1} 
                    onBlur={newContent => setAr(newContent)} 
			              onChange={newContent => {}}/>

          
        </Box>

        <Box sx={{display:languageShow === 'ar' ? 'none':'block'}}>
        <JoditEditor 
                    ref={editorConfig}
                    value={en}
                    config={editorConfig}
                    tabIndex={1} 
                    onBlur={newContent => setEn(newContent)} 
			              onChange={newContent => {}}/>

          </Box>         
          <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit {title}</Button>


              

    </Box>
  );
};
export default EditStaticText