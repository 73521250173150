import React, { useState, useEffect } from "react";

import { Routes, Route } from "react-router-dom";
import axios from "axios";
import TopBar from "./../../global/Topbar";
import SideBar from "./../../global/SideBar";
import { getAuthentication } from "../../../auth";
import ContentLanguages from "../../IngoMainPages/ContentLanguagesComps";
import InterestItems from "../../IngoMainPages/InterestItems";
import HopesFromIngo from "../../IngoMainPages/HopesFromIngo";
import Countries from "../../IngoMainPages/Countries";
import AccountsCovers from "../../IngoMainPages/AccountsCovers";
import FavouriteItems from "../../IngoMainPages/FavouriteItems";
import StaticText from "../../IngoMainPages/staticText";
import Posts from "../../IngoMainPages/Posts";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import BlockReasons from "../../IngoMainPages/BlockReasons";
import "./animations.css"; 
import { useLocation } from "react-router-dom"; 
import AccountsRelations from "../../IngoMainPages/AccountsRelations";
import FakeAccounts from "../../IngoMainPages/FakeAccounts";
import IngoDashboard from "../../IngoMainPages/Dashboard";
import Ratings from "../../IngoMainPages/Ratings";
import AwardHolders from "../../IngoMainPages/AwardHolders";
import ColorPallets from "../../IngoMainPages/ColorPallet";
import ImagePackages from "../../IngoMainPages/ImagePackage";
import CreditsPackages from "../../IngoMainPages/CreditsPackage";
import PaymentsLog from "../../IngoMainPages/PaymentsLog";
import FeedbacksContainer from "../../IngoMainPages/Feedback";
import ChatRoomBackgrounds from "../../IngoMainPages/ChatRoomBackgrounds";
import ChatRoomAvatars from "../../IngoMainPages/ChatRoomAvatars";
import ChatRoomTopNavs from "../../IngoMainPages/ChatRoomTopNavs";
import Accounts from "../../IngoMainPages/Accounts";
import Ads from "../../IngoMainPages/Ads";
import Profanity from "../../IngoMainPages/Profanity";
import ChatRoomTypes from "../../IngoMainPages/ChatRoomTypes";
import ChatRoomReportTypes from "../../IngoMainPages/ChatRoomReportsTypes";
import ChatRoomReport from "../../IngoMainPages/ChatRoomReports";
import DashboardWarning from "../../IngoMainPages/DashboardWarning";
import AppUpdate from "../../IngoMainPages/AppUpdate";

const API_URL = `https://dashapi.ingochat.com/`

const Main = ({ signOutHandler }) => {
  const [isUser, setIsUser] = useState(false);
  const [token, setToken] = useState("");
  const location = useLocation();

  const [permissions, setPermissions] = useState({
    accounts:false
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          API_URL,
          {
            query: `
              query {
                GetUserPermission {
                  id
                  permissions {
                    accounts
                    contentLanguages
                    interestsItems
                    hopesFromIngo
                    country
                    books
                    accountCovers
                    favouriteItem
                    staticText
                    posts
                    blockreasons
                    accountrelations
                    fakeAccount
                    rating
                    awardholder
                    colorpallet
                    imagepackage
                    creditspackage
                    paymentslog
                    feedback
                    chatroombackground
                    chatroomavatar
                    chatroomtopnav
                    ad
                    profanity
                    chatroomtypes
                    chatroomreportstypes
                    chatroomreports
                    dashboardwarning
                    appupdate
                  }
                }
              }
            `,
          },
          {
            headers: {
              token: `Bearer ${getAuthentication()}`,
            },
          }
        );
        if (res.status === 200) {
          if (res.data?.data?.GetUserPermission?.id !== null) {
            setPermissions((prevState) => ({
              ...prevState,
              accounts: res.data.data.GetUserPermission.permissions.accounts,
              contentLanguages:res.data.data.GetUserPermission.permissions.contentLanguages,
              interestsItems:res.data.data.GetUserPermission.permissions.interestsItems,
              hopesFromIngo:res.data.data.GetUserPermission.permissions.hopesFromIngo,
              country:res.data.data.GetUserPermission.permissions.country,
              books:res.data.data.GetUserPermission.permissions.books,
              accountCovers:res.data.data.GetUserPermission.permissions.accountCovers,
              favouriteItem:res.data.data.GetUserPermission.permissions.favouriteItem,
              staticText:res.data.data.GetUserPermission.permissions.staticText,
              posts:res.data.data.GetUserPermission.permissions.posts,
              blockreasons:res.data.data.GetUserPermission.permissions.blockreasons,
              accountrelations:res.data.data.GetUserPermission.permissions.accountrelations,
              fakeAccount:res.data.data.GetUserPermission.permissions.fakeAccount,
              rating:res.data.data.GetUserPermission.permissions.rating,
              awardholder:res.data.data.GetUserPermission.permissions.awardholder,
              colorpallet:res.data.data.GetUserPermission.permissions.colorpallet,
              imagepackage:res.data.data.GetUserPermission.permissions.imagepackage,
              creditspackage:res.data.data.GetUserPermission.permissions.creditspackage,
              paymentslog:res.data.data.GetUserPermission.permissions.paymentslog,
              feedback:res.data.data.GetUserPermission.permissions.feedback,
              chatroombackground:res.data.data.GetUserPermission.permissions.chatroombackground,
              chatroomavatar:res.data.data.GetUserPermission.permissions.chatroomavatar,
              chatroomtopnav:res.data.data.GetUserPermission.permissions.chatroomtopnav,
              ad:res.data.data.GetUserPermission.permissions.ad,
              profanity:res.data.data.GetUserPermission.permissions.profanity,
              chatroomtypes:res.data.data.GetUserPermission.permissions.chatroomtypes,
              chatroomreportstypes:res.data.data.GetUserPermission.permissions.chatroomreportstypes,
              chatroomreports:res.data.data.GetUserPermission.permissions.chatroomreports,
              dashboardwarning:res.data.data.GetUserPermission.permissions.dashboardwarning,
              appupdate:res.data.data.GetUserPermission.permissions.appupdate
            }))
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData()
  }, []);

  return (
    <>
      <SideBar Userpermissions={permissions} />
      <main className="content">
        <TopBar signOutHandler={signOutHandler} />
        
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={{ enter: 1000, exit: 1000 }}
          >
            <section className="route-section">
              <Routes>
                {permissions.accounts ? (
                  <>
                  
                    <Route path="/Accounts" element={<Accounts key="a1" />} />
                    <Route
                      path="/ContentLanguages"
                      element={<ContentLanguages key="a2" />}
                    />
                    <Route
                      path="/InterestItems"
                      element={<InterestItems key="a3" />}
                    />
                    <Route
                      path="/HopesFromIngo"
                      element={<HopesFromIngo key="a4" />}
                    />
                    <Route
                      path="/countries"
                      element={<Countries key="a5" />}
                    />
                    <Route
                      path="/accountCovers"
                      element={<AccountsCovers key="a6" />}
                    />
                    <Route
                      path="/favouriteItems"
                      element={<FavouriteItems key="a7" />}
                    />
                    <Route
                      path="/staticText"
                      element={<StaticText key="a8" />}
                    />
                    <Route path="/Posts" element={<Posts key="a9" />} />
                    <Route
                      path="/blockreasons"
                      element={<BlockReasons key="a9" />}
                    />
                     <Route
                      path="/accountRelations"
                      element={<AccountsRelations key="a10" />}
                    />
                    <Route 
                    path="/fakeAccounts" element={<FakeAccounts key="a11" />} />
                    <Route path="/Dashboard" element={<IngoDashboard key="a12" />} />
                    <Route path="/Ratings" element={<Ratings key="a13" />} />
                    <Route path="/AwardHolders" element={<AwardHolders key="a14" />} />
                    <Route path="/ColorPallets" element={<ColorPallets key="a15" />} />
                    <Route path="/ImagePackages" element={<ImagePackages key="a16" />} />
                    <Route path="/CreditsPackage" element={<CreditsPackages key="a17" />} />
                    <Route path="/PaymentsLog" element={<PaymentsLog key="a18" />} />
                    <Route path="/Feedbacks" element={<FeedbacksContainer key="a19" />} />
                    <Route path="/ChatRoomBackgrounds" element={<ChatRoomBackgrounds key="a20" />} />
                    <Route path="/ChatRoomAvatars" element={<ChatRoomAvatars key="a21" />} />
                    <Route path="/ChatRoomTopNavs" element={<ChatRoomTopNavs key="a22" />} />
                    <Route path="/Ads" element={<Ads key="a23" />} />
                    <Route path="/Profanity" element={<Profanity key="a24" />} />
                    <Route path="/chatroomtypes" element={<ChatRoomTypes key="a25" />} />
                    <Route path="/chatroomreportstypes" element={<ChatRoomReportTypes key="a26" />} />
                    <Route path="/chatroomreports" element={<ChatRoomReport key="a27" />} />
                    <Route path="/dashboardwarnings" element={<DashboardWarning key="a28" />} />
                    <Route path="/appupdate" element={<AppUpdate key="a29" />} />
                    
                    
                  </>
                ) : (
                  <></>
                )}
              </Routes>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </main>
    </>
  );
};

export default Main;
