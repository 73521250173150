import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Checkbox,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";


import AddNewCountry from "./AddNewCountry"
import CityConfig from './CityConfig'
import AddNewCity from "./AddNewCity";

const API_URL = `https://dashapi.ingochat.com/`


const CountriesGQLSchema = `
id
CountryName{
    ar
    en
}
CountryImage{
    FilePath
}
Enable
`

const Countries = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewCountry,setIsNewCountry] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [countriesCount,setCountriesCount] = useState(0)

    
    const [countries,setCountries] = useState([])
    const [selectedCountry,setSelectedCountry] = useState(null)

    const [isNewCity,setIsNewCity] = useState(true)
    const [selectedCity,setSelectedCity] = useState(null)
    const HandleMainInfoSubmit = (ar,en,filepath) =>{
        isNewCountry ? AddNewCountryFunc(ar,en,filepath) : EditCountry(ar,en,filepath)
    }

    const HandleCityComp = (ar,en) =>{
      isNewCity ? AddNewCityFunc(ar,en) : EditCity(ar,en)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const EditModalConfigStatus = (state) =>{
      setModalStatus(state)
    }
    const enableCityEdit = (city) =>{
      setSelectedCity(city)
      setIsNewCity(false)
      setModalStatus('CITYEDIT')
    }

   
    const ModalComponents = {
        "ADD": <AddNewCountry NewCountry={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewCountry NewCountry={false} Country={selectedCountry} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "CONFIG":<CityConfig Country={selectedCountry} enableCityEdit={enableCityEdit} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal} />,
        "CITYADD":<AddNewCity NewCity={true}  HandleMainInfoSubmit={HandleCityComp} closingModal={handleCloseModal}/>,
        "CITYEDIT":<AddNewCity NewCity={false} City={selectedCity} HandleMainInfoSubmit={HandleCityComp} closingModal={handleCloseModal}/>,
        
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewCountry(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleEditClick = (country) =>{
        setSelectedCountry(country)
        setModalStatus("EDIT")
        setIsNewCountry(false)
        setModalOpen(true)
    }
    
    const handleSettings =(country) =>{
      setSelectedCountry(country)
      setModalStatus("CONFIG")
      setModalOpen(true)

    }
  
    
    const handleDeleteClick = async(country) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteCountry(id:"${country.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

  const handleChangeEnable = async(value,selected_country) =>{
   
            
            const res = await axios.post(API_URL,{
              query:`
                  mutation{
                      EditCountry(id:"${selected_country.id}",ar:"${selected_country.CountryName.ar}",en:"${selected_country.CountryName.en}",filename:"${selected_country.CountryImage.FilePath}",enable:${value}){
                          ${CountriesGQLSchema}
                      }
                  }
              `
          },{
              headers: {
                token: `Bearer ${getAuthentication()}`
              }
          })

          setCountries( countries.map(item =>
            item.id === selected_country.id ? { ...item, Enable: value } : item
          ))

          if(res.data.data.EditCountry.id !== null){
              fetchData()
          }
   
  }

    const GetCountriesCount = async () =>{
      try{
        const res = await axios.post(API_URL,{
          query:`query{
            GetStaticNumber(key:"CountryCount"){
              Value
            }
          }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setCountriesCount(res.data.data.GetStaticNumber.Value)          
        }
      }
      catch(err){}
      
    }
    
    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
              GetCountires(limit:${rowsPerPage},offset:${page}){
                ${CountriesGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setCountries(res.data.data.GetCountires)
          setLoaderVisibility('none')
          GetCountriesCount()
        }
      }
      catch(err){
  
      }
    }


    const AddNewCityFunc = async(ar,en) =>{
      const res = await axios.post(API_URL,{
        query:`mutation{
          AddNewCity(ar:"${ar}",en:"${en}",countryid:"${selectedCountry.id}"){
            id
            CityName{
              ar
              en
            }
          }
        }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      
      if(res.data.data.AddNewCity.id !== null){
        setModalStatus('CONFIG')
      }
    }

    const AddNewCountryFunc = async(ar,en,filepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewCountry(ar:"${ar}",en:"${en}",filename:"${filepath}") {
                  ${CountriesGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewCountry.id !== null){
            fetchData()
          }
    }


    const EditCity = async (ar,en) =>{
      const res = await axios.post(API_URL,{
        query:`mutation{
          EditCity(id:"${selectedCity.id}",ar:"${ar}",en:"${en}"){
            id
            CityName{
              ar
              en
            }
          }
        }`
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      })
      if(res.data.data.EditCity.id !== null){
        setModalStatus('CONFIG')
      }
    }

    const EditCountry = async(ar,en,filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditCountry(id:"${selectedCountry.id}",ar:"${ar}",en:"${en}",filename:"${filepath}",enable:${selectedCountry.Enable}){
                        ${CountriesGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditCountry.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Countries`} subtitle={`Add, modify and delete countries`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Country</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Country Name (ar)</TableCell>
        <TableCell>Country Name (en)</TableCell>
        <TableCell>Enable</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    countries.map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.CountryName.ar}</TableCell>
        <TableCell>{lng.CountryName.en}</TableCell>
        <TableCell><Switch checked={lng.Enable} color="secondary" onChange={(e)=>{handleChangeEnable(e.target.checked,lng)}}/></TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(lng)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="warning" onClick={() => handleSettings(lng)}>
                <SettingsIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={countriesCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
        </Box>
    )
}

export default Countries