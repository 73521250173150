import React, { useState } from 'react'
import { Box,Dialog,DialogActions,DialogContent,DialogTitle, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { CheckOutlined, Close, CloseOutlined } from '@mui/icons-material';

const AccountImages = ({Account}) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const [mainImages,setMainImages] = useState(Account.AccountData.MainImages)
    const [gallary,setGallary] = useState(Account.AccountData.Gallary)
    const handleImageClick = (image) => {
        setSelectedImage(image);
      };
    
      const handleClosePreview = () => {
        setSelectedImage(null);
      }
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"center",
            gap:2,
            flexWrap:"wrap",
            mt:3}}>
            <Dialog open={!!selectedImage} onClose={handleClosePreview} maxWidth="md">
            <DialogActions>
          <IconButton onClick={handleClosePreview}><Close /></IconButton>
        </DialogActions>
        {selectedImage && <DialogTitle><b>Uploaded Date:</b> {new Date(selectedImage.UploadDate).toLocaleString()}</DialogTitle>}
        <DialogContent>
            {
                selectedImage &&<img
                src={`https://cloud.ingochat.com/${selectedImage.FilePath}`}
                alt="Preview"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            }
          
        </DialogContent>
        
      </Dialog>

            <Box sx={{width: "100%",border:1,borderRadius:1,p:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
                <Typography variant="h5">Image Count Allowed: <span style={{color:"#4cceac"}}>{Account.AccountData.ContentSpecification.ImageCountAllowed  ?  Account.AccountData.ContentSpecification.ImageCountAllowed : 'Not Set Yet'}</span></Typography>
                <Typography variant="h5">Used Image: <span style={{color:"#4cceac"}}>{mainImages.length + gallary.length}</span></Typography>
            </Box>
        
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",width:'100%'}}>
                <Typography sx={{width:"100%",mb:2}} variant='h5'>Main Images</Typography>
                <Box sx={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"flex-start",width:'100%',gap:2}}>
                <Box sx={{width:"30%"}}>
                    <img style={{width:"100%",aspectRatio:"1/1",objectFit:"cover",cursor:"pointer"}} onClick={() => handleImageClick(mainImages[mainImages.length -1 ])} src={`https://cloud.ingochat.com/${mainImages[mainImages.length -1 ].FilePath}`} />
                </Box>
                <Box sx={{width:"68%"}}>
                    {
                        mainImages.slice(0,-1).reverse().map((img)=>{
                           return <img style={{width:"15%",aspectRatio:"1/1",objectFit:"cover",cursor:"pointer",margin:5}} onClick={() => handleImageClick(img)} src={`https://cloud.ingochat.com/${img.FilePath}`} />

                        })
                    }
                </Box>
            </Box>
                
            </Box>
            <br /><br /><br />
            <Box sx={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",width:'100%'}}>
                <Typography sx={{width:"100%",mb:2}} variant='h5'>Gallary</Typography>
                <Box sx={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"flex-start",width:'100%',gap:2}}>
                    {
                        gallary.reverse().map((img)=>{
                           return <img style={{width:"15%",aspectRatio:"1/1",objectFit:"cover",cursor:"pointer",margin:5}} onClick={() => handleImageClick(img)} src={`https://cloud.ingochat.com/${img.FilePath}`} />
                        })
                    }
                </Box>
                
            </Box>
        </Box>
        
    )
}

export default AccountImages