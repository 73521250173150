import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "./../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const FavouriteItemCategorySchemaGQL = `
id
MainInfo{
  FavouriteItemType{
    id
    MainInfo{
      Name{
        ar
        en
      }
      Type
      Description{
        ar
        en
      }
    }
    MainImage{
      FilePath
    }
    Infos{
      categoriesCount
      Count
      createdAt
    }
  }
  Name{
    ar
    en
  }
  Description{
    ar
    en
  }
}
MainImage{
  FilePath
}
Infos{
  Count
  createdAt
}
`


const AddNewFavouriteItem = ({NewItem,Item,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ar,setAr] = useState(NewItem?'':Item.MainInfo.Name.ar)
  const [en,setEn] = useState(NewItem?'':Item.MainInfo.Name.en)

  const [arDescription,setArDescription] =useState(NewItem?'':Item.MainInfo.Description.ar)
  const [enDescription,setEnDescription] = useState(NewItem?'':Item.MainInfo.Description.en)

  const [filepath,setFilePath] = useState(NewItem?'':Item.MainImage.FilePath)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')
  const [favouriteCategories,setFavouriteItemsCategories] = useState([])


  
  const [itemType,setItemType] = useState(NewItem?'':Item.MainInfo.FavouriteItemType.id)
  const [categorytype,setCategoryType] = useState(NewItem?'':Item.MainInfo.FavouriteItemCategory.id)
  const [selectedValue,SetSelectedValue] = useState(NewItem?'':Item.MainInfo.FavouriteItemCategory.id+'-'+Item.MainInfo.FavouriteItemType.id)

  const [cdid,setCDID] = useState(NewItem?'':Item.Infos.CD_ID)
  
  const HandleSubmitBtn = () =>{ 
    HandleMainInfoSubmit(ar,en,itemType,categorytype,arDescription,enDescription,filepath,cdid)
    closingModal()
}

const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0])
  setMSG('File has been selected')
}
  
const UploadFile = async() =>{
  const formData = new FormData();
  formData.append('image', selectedFile);
  const res = await  axios.post(UPLOAD_URL, formData, {
    headers: {
      token:`Bearer ${getAuthentication()}`,
      'Content-Type': 'multipart/form-data'
    }
  })
  setFilePath(res.data.name)
  setMSG('File has uploaded successfully')
 }


 const handleChangeSelect = (value) =>{
    setItemType(value.split('-')[1])
    setCategoryType(value.split('-')[0])
    SetSelectedValue(value)
 }


    const fetchData = async () => {


      try{
   
     const res = await axios.post(API_URL,{
          query:`query{
            GetAllFavouriteItemsCategories(reverse:true){
              ${FavouriteItemCategorySchemaGQL}
            }
          }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.status === 200){
        setFavouriteItemsCategories(res.data.data.GetAllFavouriteItemsCategories)
        
      }
    }
    catch(err){

    }
  }

  useEffect(() => {
      fetchData()
      }, [])


  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewItem ? "Add New Item" : "Edit Item"}</Typography>
      {!NewItem ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Item.MainInfo.Name.ar + ' - ' + Item.MainInfo.Name.en}</Typography>):(<></>) }
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
          <Box sx={{p:1,px:2}}>
        <InputLabel color="secondary" sx={{mb:1}} id="demo-simple-select-label">Favourite Item Type  </InputLabel>
        <Select variant="filled"  value={selectedValue} onChange={(e)=>handleChangeSelect(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Type ..." >
            {
              favouriteCategories.map(tp=>(
                <MenuItem key="cvrtp1" value={tp.id + '-' + tp.MainInfo.FavouriteItemType.id} >{tp.MainInfo.Name.ar} - {tp.MainInfo.Name.en}</MenuItem>         

              ))
            }
        </Select>
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={en}
                    onChange={(e)=>setEn(e.target.value)}
                    label="Type Name (en) ..."
                    color="secondary"
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={ar}
                    onChange={(e)=>setAr(e.target.value)}
                    label="Type Name (ar) ..."
                    color="secondary"
                    />
        </Box>
                    
        <Box sx={{
            display:'flex',
            justifyContent:'space-around',p:1,px:2
        }}>


<TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={enDescription}
                    onChange={(e)=>setEnDescription(e.target.value)}
                    label="Type Description (en) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />
                      <TextField 
                    sx={{
                        mt:3,
                        width:'48%'
                    }}
                    variant="filled"
                    type="text"
                    value={arDescription}
                    onChange={(e)=>setArDescription(e.target.value)}
                    label="Type Description (ar) ..."
                    color="secondary"
                    multiline
                    rows={4}
                    />

        </Box>
         
         <Box>
         <TextField 
                    sx={{
                        mt:3,
                        width:'100%'
                    }}
                    variant="filled"
                    type="text"
                    value={cdid}
                    onChange={(e)=>setCDID(e.target.value)}
                    label="CD ID ..."
                    color="secondary"
                    />
         </Box>
        <Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>
        </Box>
        <Box>
              <Typography sx={{textAlign:'center',mt:1}} color="error">{msg}</Typography>
        </Box>   
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewItem? `Add New Item` : `Edit Item`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewFavouriteItem