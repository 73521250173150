import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const InterestSchemaGQL = `
id
InterestName{
  ar
  en
}
`

const DisplayPost = ({Post,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  
  const detectTextDirection = (text) => {
    const rtlRegex = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
    return rtlRegex.test(text) ? "right" : "left";
  }
  
 
  const convertToEmbedLink = (link) => {
    const videoId = link.split("v=")[1];
    const embedLink = `https://www.youtube.com/embed/${videoId}`;
    return embedLink;
  }
  

  


  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{textAlign:"center"}}>Post Details</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
         
                <Box sx={{
                    mt:3
                }}>
                    <Box sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:2,
                        borderBottom:1,
                        pb:3
                    }}>
                        {(Post.Infos.CreatedBy?.AccountData.MainImages.length > 0 && Post.Infos.IngoAccount === false) ? <Box sx={{width:"90px",height:"90px",padding:1,overflow:"hidden",borderRadius:"100%",backgroundColor:"#fafafa"}}><img src={`https://cloud.ingochat.com/${Post.Infos.CreatedBy.AccountData.MainImages[Post.Infos.CreatedBy.AccountData.MainImages.length - 1].FilePath}`}/>  </Box>: <Box sx={{width:"90px",height:"90px",padding:1,overflow:"hidden",borderRadius:"100%",backgroundColor:"#fafafa"}}><img style={{width:"100%"}} src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-File.png" /> </Box>}
                        <Box>
                            <Typography variant="h3">{Post.Infos.IngoAccount ? "INGO Dashboard ": Post.Infos.CreatedBy?.AccountData.MainInfo.FullName}</Typography>
                            <Typography variant="h6">{new Date(Post.Infos.createdAt).toLocaleString()}</Typography>

                        </Box>
                        

                    </Box>

                    <Box sx={{
                        p:1
                    }}>
                        <Typography sx={{
                            textAlign: detectTextDirection(Post.MainInfo.Standard.Text),
                            fontSize:"18px",
                            mb:2
                        }}>
                            {Post.MainInfo.Standard.Text}
                        </Typography>

                        {
                            Post.MainInfo.Standard.isEmbed && Post.MainInfo.Standard.EmbedObj.Type === 'youtube' && <iframe style={{width:"100%"}} src={convertToEmbedLink(Post.MainInfo.Standard.EmbedObj.Link)}></iframe>
                        }
                    </Box>
                    <Box sx={{
                        p:1,
                        display:"grid",
                        gridTemplateColumns:Post.MainInfo.Standard.Images.length > 1 ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
                        gap:2
                    }}>
                        {
                            Post.MainInfo.Standard.Images.map((img)=>{
                                return <img style={{width:"100%"}} src={`https://cloud.ingochat.com/${img}`} />
                            })
                        }
                    </Box>
                    <Box sx={{
                        p:1,
                        display:"flex",
                        flexDirection:"row",
                        flexWrap:"wrap",
                        gap:1,
                        justifyContent:"center"
                    }}>
                        {
                            Post.MainInfo.Interests.map((intrst)=>{
                                return <span style={{
                                    border:1,
                                    display:"inline",
                                    backgroundColor:"#1e5245",
                                    padding:"5px",
                                    fontSize:"17px",
                                    borderRadius:"5px"


                                }}>{intrst.InterestName.ar} - {intrst.InterestName.en}</span>
                            })
                        }
                    </Box>
                    
                    <Box sx={{mt:10}}>
                        //Likes <br/>
                        //Comments <br/>
                        //Shares <br/>

                    </Box>

                </Box>
                 
          
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default DisplayPost;