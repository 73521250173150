import { Box,FormLabel,RadioGroup,Radio, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
const API_URL = `https://dashapi.ingochat.com/`;
const UPLOAD_URL = `https://upload.ingochat.com/`

const InterestSchemaGQL = `
id
InterestName{
  ar
  en
}
`

const AddNewPoll = ({NewPoll,Poll,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [allInterests,setAllInterests] = useState([])

  const [question,setQuestion] = useState(NewPoll?'':Poll.MainInfo.Poll.Question)
  const [pollType,setPollType] = useState(NewPoll?'text':Poll.MainInfo.Poll.PollType)
  const [options,setOptions] = useState(NewPoll?[]:Poll.MainInfo.Poll.Options)
  const [interests,setInterests] = useState(NewPoll?[]:Poll.MainInfo.Interests)
  const [selectedFile, setSelectedFile] = useState(null)
  const [msg,setMSG] = useState('')
  const [optText,setOptText] = useState('')
  const [uploadedImg,setUploadedImg] = useState('')

  const HandleSubmitBtn = () =>{
      let intrst = interests.map((interest) => interest.id).join(',')
      let txts = options.map((opt)=> opt.text).join(';')
      let imgs = pollType !== 'text' ?  options.map((opt)=>opt.img).join(',') : ''
      HandleMainInfoSubmit(pollType,question,txts,imgs,intrst)
      closingModal()
  }

  const addNewTextOPtion = () =>{
      let obj = {
        text:optText,
        img:""
      }
      setOptText('')
      setOptions([...options,obj])
  }

  const addNewComOPtion = () =>{
    let obj = {
        text:optText,
        img:uploadedImg
      }
      setOptText('')
      setUploadedImg('')
      setSelectedFile(null)
      setMSG('')
      setOptions([...options,obj])
  }
  
  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setUploadedImg(res.data.name)
    setMSG('B')

   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('A')
  }

  const moveItemToTarget = (item) => {
    setAllInterests(allInterests.filter((i) => i !== item));
    setInterests([...interests, item]);
  }

  const moveItemToSource = (item) => {
    setInterests(interests.filter((i) => i !== item));
    setAllInterests([...allInterests, item]);
  };

  
  const fetchData = async () => {
    try{
      const res = await axios.post(API_URL,{
            query:`query{
              GetInterestItems{
                ${InterestSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          let allItems = res.data.data.GetInterestItems;
          let newInterests = allItems.filter((item) => !interests.some((interest) => interest.id === item.id))
          setAllInterests(newInterests);
        }
  }
  catch(err){

  }
}

const ChangePollType = (type) =>{
  if(type === 'text'){
    if(options.length > 0){
      if(options[0].img !== ''){
        setOptions([])
      }
    }
  }
  else if(type !== 'text'){
    if(options.length>0){
      if(options[0].img === ''){
        setOptions([])
      }
    }
  }
  setPollType(type)
}

useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Add New Poll</Typography>

      <Typography sx={{
        textAlign:"center"
      }}>You can't edit the poll after submitting it </Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
          display:"flex",
          justifyContent:"space-around"
        }}>
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            width:"58%"
        }}>
                <TextField 
                    sx={{
                        mt:3,
                        mb:4
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={question}
                    onChange={(e)=>setQuestion(e.target.value)}
                    label="Poll Question Text ..."
                    color="secondary"
                    multiline
                    rows={3} 
                    />
              
              
              <FormControl>
      <Typography variant="h6">Poll Type </Typography>
      <Typography color="error">When transitioning between text and an image, it will clear the temporary options data.</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="text"
        value={pollType}
        onChange={(e)=>ChangePollType(e.target.value)}
        name="radio-buttons-group"
        sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-around"
        }}
      >
        <FormControlLabel value="text" control={<Radio  color="secondary"/>} label="Text" />
        <FormControlLabel value="img_flex" control={<Radio  color="secondary"/>} label="Image Flex" />
        <FormControlLabel value="img_grid" control={<Radio  color="secondary"/>} label="Image Grid" />
      </RadioGroup>
    </FormControl>

    <Box sx={{mt:4}}>
        {
            pollType === 'text' ? (
                <>
                    <Box sx={{display:"flex",flexDirection:"row"}}>
                        <TextField  variant="filled"
                                type="text"
                                value={optText}
                                onChange={(e)=>setOptText(e.target.value)}
                                label="Option Text ..."
                                color="secondary"
                                sx={{
                                    width:'75%'
                                }}
                        />
                        <Button onClick={addNewTextOPtion} sx={{mx:2}} variant="contained" color="secondary">Add New Option</Button>

                    </Box>
                    <Box sx={{mt:2}}>
                            {
                            options.map((opt) => (
                                <Box sx={{
                                    display:"flex",
                                    flexDirection:"row",
                                    justifyContent:"space-between",
                                    borderBottom:1,
                                    px:2,
                                    mt:2
                                }}>
                                    <Typography variant="h5" key={opt.text}>{opt.text}</Typography>
                                    <IconButton color="error" onClick={() =>setOptions(options.filter((s) => s.text !== opt.text)) }> 
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))
                            }
                    </Box>

                </>
            ):(
                <>
                
                <Box sx={{display:"flex",flexDirection:"row"}}>
                        <TextField  variant="filled"
                                type="text"
                                value={optText}
                                onChange={(e)=>setOptText(e.target.value)}
                                label="Option Text ..."
                                color="secondary"
                                sx={{
                                    width:'40%'
                                }}
                        />
                        <Button sx={{mx:1,width:"15%"}}  color={msg !== '' ? 'success' : 'secondary'} variant={msg !== '' ? 'contained' : 'outlined'} component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
                        <Button sx={{mx:1,width:"22%"}}  color={msg === 'B' ? 'success' : 'warning'} variant="contained" component="label" onClick={UploadFile} >Upload</Button>
                        <Button onClick={addNewComOPtion} sx={{mx:2}} variant="contained" color="secondary">Add New Option</Button>

                    </Box>
                    <Box sx={{mt:2}}>
                            {
                            options.map((opt) => (
                                <Box sx={{
                                    display:"flex",
                                    flexDirection:"row",
                                    justifyContent:"space-between",
                                    borderBottom:1,
                                    px:2,
                                    mt:2
                                }}>
                                    <Typography variant="h5" key={opt.text}>{opt.text}</Typography>
                                    <Box>
                                        <IconButton color="secondary" onClick={() =>window.open('https://cloud.ingochat.com/'+opt.img, '_blank') }> 
                                            <RemoveRedEyeIcon />
                                        </IconButton>
                                        <IconButton color="error" onClick={() =>setOptions(options.filter((s) => s.text !== opt.text)) }> 
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                    
                                </Box>
                            ))
                            }
                    </Box>
                </>
            )
        }
    </Box>


                    
        </Box>

        <Box sx={{
          width:'38%',
          mt:3,
          display:"flex",
          justifyContent:"space-around"
        }}>
          <List sx={{
            border:1,
            px:1,
            borderColor:"#ccc",
            borderRadius:1,
            width:'49%',
            height:"50vh",
            overflowY:"scroll"
          }}>
          <Typography variant="h5" sx={{textAlign:"center"}}>Interests</Typography>
          {allInterests.map((item) => (
          <ListItem sx={{
            borderBottom:1,
            borderColor:"#aaa"

          }} key={item.id}>
            <Typography sx={{
              width:"80%",
            }} variant="h6">
              {item.InterestName.ar} - {item.InterestName.en}
            </Typography>
            <IconButton color="secondary" onClick={() => moveItemToTarget(item)}><KeyboardArrowRightIcon/></IconButton>
          </ListItem>
        ))}
      
      

          </List>

          <List  sx={{
            border:1,
            borderColor:"#ccc",
            px:1,
            borderRadius:1,

            width:'49%',
            height:"50vh",
            overflowY:"scroll"
          }}>
                      <Typography variant="h5" sx={{textAlign:"center"}}> Choosen Interests</Typography>

        {interests.map((item) => (
          <ListItem sx={{
            borderBottom:1,
            borderColor:"#aaa"

          }} key={item.id}>
 <Typography sx={{
              width:"80%",
            }} variant="h6">
              {item.InterestName.ar} - {item.InterestName.en}
            </Typography>            <IconButton color="error" onClick={() => moveItemToSource(item)}><KeyboardArrowLeftIcon/></IconButton>
          </ListItem>
        ))}
      </List>
        </Box>
      
        
    
            
              
        </Box>
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Poll</Button>

        </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default AddNewPoll