import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import { useState,useEffect } from "react"
import { tokens } from "../../../theme";
import axios from "axios"
import { getAuthentication } from "../../../auth"


import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AddNewFavouriteItemType from "./AddNewFavouriteItemType";
const API_URL = `https://dashapi.ingochat.com/`

const FavouriteItemTypeSchemaGQL = `
id
MainInfo{
  Name{
    ar
    en
  }
  Type
  Description{
    ar
    en
  }
}
MainImage{
  FilePath
}
Infos{
  categoriesCount
  Count
  createdAt
}
`

const FavouriteItemTypes = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [favouriteItemTypes,setFavouriteItemTypes] = useState([])
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [selectedType,setSelectedType] = useState(null)
    const [isNewType,setIsNewType] = useState(true)

    const HandleMainInfoSubmit = (ar,en,typeType,arDescription,enDescription,filepath) =>{
        isNewType ? AddNewTypeFunc(ar,en,typeType,arDescription,enDescription,filepath) : EditTypeFunc(ar,en,typeType,arDescription,enDescription,filepath)
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents = {
        "ADD": <AddNewFavouriteItemType NewType={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewFavouriteItemType NewType={false} Type={selectedType} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const AddNewTypeFunc = async(ar,en,typeType,arDescription,enDescription,filepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewFavouriteItemType(ar:"${ar}",en:"${en}",typeType:"${typeType}",arDesciption:"${arDescription}",enDescription:"${enDescription}",filepath:"${filepath}") {
                  ${FavouriteItemTypeSchemaGQL}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewFavouriteItemType.id !== null){
            fetchData()
          }
    }

    const EditTypeFunc = async(ar,en,typeType,arDescription,enDescription,filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditFavouriteItemType(id:"${selectedType.id}",ar:"${ar}",en:"${en}",typeType:"${typeType}",arDesciption:"${arDescription}",enDescription:"${enDescription}",filepath:"${filepath}"){
                        ${FavouriteItemTypeSchemaGQL}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditFavouriteItemType.id !== null){
            fetchData()
        }
    }

    const handleNewBtnClick = () =>{
        setIsNewType(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleDeleteClick = async(type) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteFavouriteItemType(id:"${type.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

    const handleEditClick = (type) =>{
        setSelectedType(type)
        setModalStatus("EDIT")
        setIsNewType(false)
        setModalOpen(true)
    }

    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
                GetAllFavouriteItemsType(reverse:true){
                ${FavouriteItemTypeSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
            setFavouriteItemTypes(res.data.data.GetAllFavouriteItemsType)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }

    useEffect(() => {
        fetchData()
        }, [])


    return(
       <Box sx={{
            mt:2
       }}> 
       <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{display:isLoaderVisible === 'flex' ? 'flex' : 'none',justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Type</Button>
            </Box>


            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Type Name (ar)</TableCell>
        <TableCell>Type Name (en)</TableCell>
        <TableCell>Categories Count</TableCell>
        <TableCell>Items Count</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    favouriteItemTypes.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(ctg => (
        <TableRow key={ctg.id}>
        <TableCell>{ctg.MainInfo.Name.ar}</TableCell>
        <TableCell>{ctg.MainInfo.Name.en}</TableCell>
        <TableCell>{ctg.Infos.categoriesCount}</TableCell>
        <TableCell>{ctg.Infos.Count}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(ctg)}> 
                <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(ctg)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={favouriteItemTypes.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
       </Box>
    )
}
export default FavouriteItemTypes