import React, { useEffect, useState } from 'react'
import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
import axios from 'axios';
import { getAuthentication } from '../../../auth';
const API_URL = `https://dashapi.ingochat.com/`

const AccountLogGQLSchema = `
id
Category
RelatedAccount{
    AccountData{
        MainInfo{
            FullName
        }
    }
}
CreatedAt
`

const AccountLogs = ({Account}) => {

    const [page,setPage] = useState(0)
    const [limit,setLimit] = useState(50)
    const [logs,setLogs] = useState([])
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetLogsOfAccount(id:"${Account.id}",limit:${limit},offset:${page}){
                        ${AccountLogGQLSchema}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
            setLogs(res.data.data.GetLogsOfAccount)
            setLoaderVisibility('none')
        }
      }
      catch(err){}
    }

    useEffect(()=>{
        fetchData()
    },[limit,page])

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
        },
      }}
    >
        {
            logs.map((lg)=>{
                return <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    {new Date(lg.CreatedAt).toLocaleString()}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector color="secondary" />
                </TimelineSeparator>
                <TimelineContent>{lg.Category.replace(/([a-z])([A-Z])/g, '$1 $2')}</TimelineContent>
              </TimelineItem>
            })
        }
      
    </Timeline>
  )
}

export default AccountLogs