import { Box,useTheme,Tab,Tabs,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import { useState,useEffect } from "react"
import { tokens } from "../../../theme";
import axios from "axios"
import { getAuthentication } from "../../../auth"


import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AddNewRatingType from "./AddNewRatingType";
const API_URL = `https://dashapi.ingochat.com/`

const RatingTypeSchemaGQL = `
id
MainInfo{
  Title{
    Ar
    En
  }
  Description{
    Ar
    En
  }
}
RateConfig{
    isThereRateKeys
    RateKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
        Key3{
            Enabled
            Ar
            En
        }
        Key4{
            Enabled
            Ar
            En
        }
    }
    RateFrom
    OtherKeys{
        Key1{
            Enabled
            Ar
            En
        }
        Key2{
            Enabled
            Ar
            En
        }
    }
}
Infos{
    categoriesCount
    Count
    CreatedAt
}
`

const RatingType = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [ratingsTypes,setRatingTypes] = useState([])
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [selectedType,setSelectedType] = useState(null)
    const [isNewType,setIsNewType] = useState(true)

    const HandleMainInfoSubmit = (ar,en,arDescription,enDescription,isThereRateKeys,rateFrom,ratekeys_str,otherRates_str) =>{
       AddNewTypeFunc(ar,en,arDescription,enDescription,isThereRateKeys,rateFrom,ratekeys_str,otherRates_str) 
    }

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents = {
        "ADD": <AddNewRatingType  HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const AddNewTypeFunc = async (ar,en,arDescription,enDescription,isThereRateKeys,rateFrom,ratekeys_str,otherRates_str) => {
        const res = await axios.post(API_URL, {
            query: `
              mutation($ratekeys: String!, $otherkeys: String!) {
                AddNewRatingType(
                  titlear: "${ar}",
                  titleen: "${en}",
                  descar: "${arDescription}",
                  descen: "${enDescription}",
                  isThereRateKeys: ${isThereRateKeys},
                  ratefrom: ${rateFrom},
                  ratekeys: $ratekeys,
                  otherkeys: $otherkeys
                ) {
                  ${RatingTypeSchemaGQL}
                }
              }
            `,
            variables: {
              ratekeys: JSON.stringify(ratekeys_str),
              otherkeys: JSON.stringify(otherRates_str)
            }
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
          if(res.data.data.AddNewRatingType.id !== null){
            fetchData()
          }
    }

    const handleNewBtnClick = () =>{
        setIsNewType(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleDeleteClick = async(type) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteRatingType(id:"${type.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

 

    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
                GetAllRatingTypes(limit:${rowsPerPage},offset:${page}){
                ${RatingTypeSchemaGQL}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setRatingTypes(res.data.data.GetAllRatingTypes)
          setLoaderVisibility('none')
          
        }
      }
      catch(err){
  
      }
    }

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage])


    return(
       <Box sx={{
            mt:2
       }}> 
       <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Type</Button>

                    <Box   sx={{display:isLoaderVisible === 'flex' ? 'flex' : 'none',justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>


            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Type Name (ar)</TableCell>
        <TableCell>Type Name (en)</TableCell>
        <TableCell>Categories Count</TableCell>
        <TableCell>Items Count</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    ratingsTypes.map(ctg => (
        <TableRow key={ctg.id}>
        <TableCell>{ctg.MainInfo.Title.Ar}</TableCell>
        <TableCell>{ctg.MainInfo.Title.En}</TableCell>
        <TableCell>{ctg.Infos.categoriesCount}</TableCell>
        <TableCell>{ctg.Infos.Count}</TableCell>
        <TableCell>
           
            <IconButton color="error" onClick={() => handleDeleteClick(ctg)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={ratingsTypes.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
       </Box>
    )
}
export default RatingType