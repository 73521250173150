import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from "axios"

import EditStaticText from './EditStaticText'
import { tokens } from "../../../theme"

import { getAuthentication } from "../../../auth"


const API_URL = `https://dashapi.ingochat.com/`

const StaticTextGQL = `
id
Key
Value{
    ar
    en
}
Enable
`



const StaticText = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [selectedValue,setSelectedValue] = useState(null)
    const [selectedStaticText,setSelectedStaticText] = useState(null)
    const [selectedObjTitle,setSelectedObjTitle] = useState('')
    
    const handleEditTextSubmit = (ar,en,enable) =>{
        setStaticText(ar,en,enable)
    }
   
    const ModalComponents = {
        'privacy_policy': <EditStaticText statictext={selectedStaticText}  HandleMainInfoSubmit={handleEditTextSubmit} lngtitle={selectedObjTitle} />
    }


    const handleChangeSelect = (value) =>{
        const words = value.replace(/_/g, ' ').split(' ')
        const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
        const titleCaseString = titleCaseWords.join(' ')
        setSelectedValue(value)
        setSelectedObjTitle(titleCaseString)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const setStaticText = async(ar,en,enable) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                SetStaticText(key:${selectedValue},ar:${JSON.stringify(ar)},en:${JSON.stringify(en)},enable:${enable}) 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }
    

    const fetchData = async() =>{
        try{
        const res = await axios.post(API_URL,{
                query:`query{
                    GetStaticText(key:${selectedValue}){
                        ${StaticTextGQL}
                    }
                }`
            },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
            })
        if(res.status === 200){
          setSelectedStaticText(res.data.data.GetStaticText)
          setModalStatus(selectedValue)
        }
      }
      catch(err){
  
      }

    }

    useEffect(() => {
        fetchData()
    }, [selectedValue]);

    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Static Text`} subtitle={`Modify Static Text`} />
    

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between",
                flexDirection:"column"
            }}>
                   

<InputLabel color="secondary" sx={{mb:1}} id="demo-simple-select-label">Static Text Type </InputLabel>
        <Select variant="filled" sx={{width:'30%'}}  value={selectedValue} onChange={(e)=>handleChangeSelect(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Type ..." >
                <MenuItem key="key_privacy_policy" value="privacy_policy" >Privacy Policy</MenuItem>         
        </Select>
                     
                  
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
                {ModalComponents[modalStatus]}

         
        </Box>
        </Box>
    )
}

export default StaticText